import { usePresentationSpeaker, useSpeakerCreate, useSpeakerUpdate } from "@api";
import {
	SpeakerForm,
	type SpeakerModalForm,
	speakerDefaultValues,
	speakerReadToSpeakerForm,
	speakerReadToUserInformationProps,
	speakerSchema,
	typeContactToParticipantWrite,
} from "@components";
import { speakerFormToSpeakerWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateSpeakerFromContact } from "@hooks";
import {
	type CustomFieldForm,
	CustomFieldFormTabs,
	DialogAdvanced,
	type UserInformationProps,
	getDialogAdvanced,
	useContextModule,
	useSnackbar,
} from "@key4-front-library/core";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface SpeakerModalProps {
	sessionId: string;
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	presentationId: string;
	speakerId?: string;
}

export const SpeakerModal = (props: SpeakerModalProps) => {
	const component = "speakerModal";
	const { sessionId, isOpened, onClose, presentationId, speakerId, onCallbackSubmit } = props;

	const [isLoaded, setIsLoaded] = useState(false);
	const [userInformation, setUserInformation] = useState<UserInformationProps>();

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const presentationSpeakerQuery = usePresentationSpeaker(client.id, event.id, sessionId, presentationId, speakerId ?? "", { enabled: !!speakerId });

	const form = useForm<CustomFieldForm<SpeakerModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: speakerDefaultValues,
		resolver: zodResolver(speakerSchema(speakerId)),
	});

	useEffect(() => {
		if (presentationSpeakerQuery.data) {
			setUserInformation(speakerReadToUserInformationProps(presentationSpeakerQuery.data));
		}
	}, [presentationSpeakerQuery.data]);

	useEffect(() => {
		if ((presentationSpeakerQuery.data || !speakerId) && isLoaded) {
			form.reset(speakerReadToSpeakerForm(form.getValues("customFields"), presentationSpeakerQuery.data));
		}
	}, [form.reset, presentationSpeakerQuery.data, form.getValues, isLoaded, speakerId]);

	const handleSpeakerSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: speakerId ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const createSpeakerFromContact = useCreateSpeakerFromContact({ onSuccess: handleSpeakerSubmit });
	const createSpeaker = useSpeakerCreate({ onSuccess: handleSpeakerSubmit });
	const speakerEdit = useSpeakerUpdate({ onSuccess: handleSpeakerSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<SpeakerModalForm>) => {
			if (speakerId && presentationSpeakerQuery.data) {
				speakerEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					participantId: presentationSpeakerQuery.data.participantId,
					body: speakerFormToSpeakerWrite(data),
				});
				return;
			}
			if (data.participantOrContact?.category === "contacts") {
				createSpeakerFromContact.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					body: speakerFormToSpeakerWrite(data),
					bodyParticipant: typeContactToParticipantWrite(data.participantOrContact),
				});
				return;
			}

			if (data.participantOrContact?.category === "participants") {
				createSpeaker.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					participantId: data.participantOrContact.id,
					body: speakerFormToSpeakerWrite(data),
				});
				return;
			}
			snackbar.enqueue({ type: "unexpected" });
		},
		[
			client.id,
			event.id,
			speakerId,
			speakerEdit,
			sessionId,
			createSpeaker,
			createSpeakerFromContact,
			presentationId,
			snackbar.enqueue,
			presentationSpeakerQuery.data,
		],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<SpeakerModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	if (userInformation === undefined && speakerId) {
		return undefined;
	}

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: speakerId ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
			fullWidth
		>
			<FormProvider {...form}>
				{
					<CustomFieldFormTabs
						onIsLoaded={handleIsLoaded}
						module="programme"
						scope="speaker"
						businessForm={<SpeakerForm isEditMode={!!speakerId} userInformation={userInformation} />}
					/>
				}
			</FormProvider>
		</DialogAdvanced>
	);
};
