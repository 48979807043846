import { useSession, useSessionCreate, useSessionTags, useSessionUpdate } from "@api";
import { SessionForm, type SessionModalForm, sessionDefaultValues, sessionReadToSessionForm, sessionSchema } from "@components";
import { sessionFormToSessionWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { type CustomFieldForm, CustomFieldFormTabs, DialogAdvanced, getDialogAdvanced, useContextModule, useSnackbar } from "@key4-front-library/core";
import { t } from "i18next";
import type { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface InjectSessionData {
	roomId?: string | null;
	publicationDate?: DateTime | null;
	startDate?: DateTime | null;
	endDate?: DateTime | null;
}
export interface SessionModalProps {
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	id?: string;
	injectSessionData?: InjectSessionData;
}

export const SessionModal = (props: SessionModalProps) => {
	const component = "sessionModal";
	const { isOpened, onClose, id, onCallbackSubmit, injectSessionData } = props;

	const [isLoaded, setIsLoaded] = useState(false);

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const sessionQuery = useSession(client.id, event.id, id ?? "", { enabled: !!id, queryStrings: [{ key: "includeCustomFields", value: "true" }] });
	const sessionTagsQuery = useSessionTags(client.id, event.id, id ?? "", { enabled: !!id });

	const form = useForm<CustomFieldForm<SessionModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: sessionDefaultValues(injectSessionData),
		resolver: zodResolver(sessionSchema),
	});

	useEffect(() => {
		if (sessionQuery.isFetched && sessionQuery.data && sessionTagsQuery.isFetched && isLoaded) {
			form.reset({
				...sessionReadToSessionForm(sessionQuery.data, form.getValues("customFields"), sessionTagsQuery.data?.data.slice(1)),
				...injectSessionData,
			});
		}
	}, [
		form.reset,
		sessionQuery.isFetched,
		sessionQuery.data,
		sessionTagsQuery.data?.data,
		sessionTagsQuery.isFetched,
		form.getValues,
		injectSessionData,
		isLoaded,
	]);

	const handleSessionSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: id ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const sessionCreate = useSessionCreate({ onSuccess: handleSessionSubmit });
	const sessionEdit = useSessionUpdate({ onSuccess: handleSessionSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<SessionModalForm>) => {
			if (id) {
				sessionEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId: id,
					body: sessionFormToSessionWrite(data, sessionQuery.data),
				});
			} else {
				sessionCreate.mutate({
					clientId: client.id,
					eventId: event.id,
					body: sessionFormToSessionWrite(data),
				});
			}
		},
		[client.id, event.id, sessionCreate, id, sessionEdit, sessionQuery.data],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<SessionModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: id ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
			fullWidth
		>
			<FormProvider {...form}>
				<CustomFieldFormTabs onIsLoaded={handleIsLoaded} module="programme" scope="session" businessForm={<SessionForm id={id} />} />
			</FormProvider>
		</DialogAdvanced>
	);
};
