import { TIME_FORMAT } from "@key4-front-library/core/Constants/Date";
import { t } from "i18next";

const GetTranslationsForSettingsScheduler = () => {
	return {
		title: t(`old.programme.settings.title`),
		scheduler: {
			title: t(`old.programme.settings.scheduler.title`),
			settings: {
				startDate: t(`old.programme.settings.scheduler.settings.startDate`),
				endDate: t(`old.programme.settings.scheduler.settings.endDate`),
				startTime: t(`old.programme.settings.scheduler.settings.startTime`),
				endTime: t(`old.programme.settings.scheduler.settings.endTime`),
				dayTimeSlot: t(`old.programme.settings.scheduler.settings.dayTimeSlot`),
				programmeDuration: t(`old.programme.settings.scheduler.settings.programmeDuration`),
			},
		},
		unities: {
			day: t(`old.common.unities.day.single`),
			days: t(`old.common.unities.day.multiple`),
			hour: t(`old.common.unities.hour.single`),
			hours: t(`old.common.unities.hour.multiple`),
		},
		dateMaskFormat: t("old.common.date-mask-format"),
		timeMaskFormat: TIME_FORMAT,
		button: t("old.form.buttons.edit"),
	};
};

export default GetTranslationsForSettingsScheduler;
