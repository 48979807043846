import {
	type BulkUpdatePresentationsDatesArgs,
	type PresentationBulkDateUpdate,
	type PresentationRead,
	type PresentationWrite,
	bulkUpdatePresentationsDates,
	getPresentation,
	getPresentations,
	postPresentation,
	putPresentation,
} from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type Paginated,
	QUERY_KEYS_PROGRAMME,
	type UseMutationArgs,
	type UseQueryArgs,
	overrideQueryParams,
} from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const usePresentations = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<PresentationRead>> = {},
): UseQueryResult<Paginated<PresentationRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.presentations, ...queryKeyPart, "Get", clientId, eventId, sessionId, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getPresentations({
				clientId,
				eventId,
				sessionId,
				queryStrings: overriddenQueryStrings,
				signal,
			}),
		...others,
	});
};

export const usePresentation = (
	clientId: string,
	eventId: string,
	sessionId: string,
	id: string,
	args: UseQueryArgs<PresentationRead> = {},
): UseQueryResult<PresentationRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const overriddenQueryStrings = overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]);

	return useQuery<PresentationRead>({
		queryKey: [...QUERY_KEYS_PROGRAMME.presentations, ...queryKeyPart, "Get", clientId, eventId, sessionId, id, overriddenQueryStrings],
		queryFn: ({ signal }) =>
			getPresentation({
				clientId,
				eventId,
				sessionId,
				id,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export interface BulkUpdatePresentationsDatesRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	id: string;
	body: Array<PresentationBulkDateUpdate>;
	signal?: AbortSignal;
}

export const useBulkUpdatePresentationsDatesUpdate = (
	args: UseMutationArgs<BulkUpdatePresentationsDatesArgs> = {},
): UseMutationResult<boolean, Error, BulkUpdatePresentationsDatesArgs> => {
	return useMutation<boolean, Error, BulkUpdatePresentationsDatesArgs>({
		mutationFn: (request: BulkUpdatePresentationsDatesArgs) => bulkUpdatePresentationsDates(request),
		...args,
	});
};

export interface PresentationCreateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationWrite;
	signal?: AbortSignal;
}

export const usePresentationCreate = (
	args: UseMutationArgs<PresentationCreateRequest, string>,
): UseMutationResult<string, Error, PresentationCreateRequest> => {
	return useMutation({
		mutationFn: (request: PresentationCreateRequest) => postPresentation(request),
		...args,
	});
};

export interface PresentationUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	body: PresentationWrite;
	signal?: AbortSignal;
}

export const usePresentationUpdate = (args: UseMutationArgs<PresentationUpdateRequest>): UseMutationResult<boolean, Error, PresentationUpdateRequest> => {
	return useMutation({
		mutationFn: (request: PresentationUpdateRequest) => putPresentation(request),
		...args,
	});
};
