import { type SessionTemplateRead, getSessionTemplates } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useSessionTemplates = (
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<SessionTemplateRead>> = {},
): UseQueryResult<Paginated<SessionTemplateRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;

	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<Paginated<SessionTemplateRead>>({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessionTemplates, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getSessionTemplates({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
