import type { SessionRead } from "@api";
import type { PresentationModalForm } from "@components";
import { durationZod } from "@helpers";
import { type CustomFieldForm, customFieldsZod, dateNullableZod, dateStartEndZod, getDateTime, stringISOToDateTime } from "@key4-front-library/core";
import { t } from "i18next";
import { z } from "zod";

export const presentationDefaultValues: CustomFieldForm<PresentationModalForm> = {
	code: "",
	title: "",
	description: "",
	startDate: null,
	startHour: null,
	endDate: null,
	endHour: null,
	duration: "",
	participantOrContact: null,
	qualityId: "",
	customFields: {},
};

const baseSchema = z.object({
	code: z.string(),
	title: z.string(),
	description: z.string(),
	startDate: dateNullableZod,
	startHour: dateNullableZod,
	endDate: dateNullableZod,
	endHour: dateNullableZod,
	duration: durationZod(),
	customFields: customFieldsZod,
	participantOrContact: z.any(),
	qualityId: z.string().optional(),
});

export const presentationSchema = (id?: string) =>
	dateStartEndZod(baseSchema).superRefine((data, ctx) => {
		if (data.participantOrContact === null && !id) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.required"),
				path: ["participantOrContact"],
			});
		}

		if (data.startHour !== null || data.endHour !== null) {
			if (!data.startHour) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t("form.validation.required"),
					path: ["startHour"],
				});
			}
			if (!data.endHour) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t("form.validation.required"),
					path: ["endHour"],
				});
			}
		}

		if (data.startHour !== null && data.endHour !== null) {
			if (getDateTime(data.startDate, data.startHour) >= getDateTime(data.endDate, data.endHour)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: t("form.validation.date.endDateMustBeSuperiorToStartDate"),
					path: ["endHour"],
				});
			}
		}
	});

/**
 * Two cases: session with on one day or session without timeslot
 *
 * @param session
 */
export const isPresentationDateDisabled = (session: SessionRead): boolean => {
	if (!session.startDate || !session.endDate) {
		return true;
	}
	const start = stringISOToDateTime(session.startDate);
	const end = stringISOToDateTime(session.endDate);

	return start.hasSame(end, "year") && start.hasSame(end, "month") && start.hasSame(end, "day");
};
