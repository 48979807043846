import { type PresentationRead, useBulkUpdatePresentationsDatesUpdate, usePresentations, useSession, useSessionUpdate, useTags } from "@api";
import {
	type FormPresentationsEdit,
	type PresentationEditSchedule,
	type SessionEditSchedule,
	formPresentationEditValidationSchema,
	presentationEditScheduleToPresentationBulkDateUpdate,
	reschedulePresentations,
	sessionEditScheduleToSessionWrite,
} from "@application/Dialogs";
import { ScheduleUpdate } from "@application/components/Molecules";
import { TitleLink } from "@application/components/Molecules/TitleLink";
import {
	DialogAdvanced,
	EMenuPath,
	ExternalRouting,
	TitleDivider,
	Typography,
	getButton,
	getTitleDivider,
	sortToQueryString,
	useContextModule,
	useResolver,
} from "@key4-front-library/core";
import { Box } from "@mui/material";
import { t } from "i18next";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

export interface EditPresentationsScheduleProps {
	sessionEditSchedule: SessionEditSchedule | undefined;
	isOpen: boolean;
	onClose: () => void;
}

export const EditPresentationsSchedule = (props: EditPresentationsScheduleProps): React.ReactNode => {
	const { sessionEditSchedule, isOpen, onClose } = props;

	const component = "editPresentationsSchedule";
	const { client, event } = useContextModule();
	const snackbar = useSnackbar();

	const [presentations, setPresentations] = useState<Array<PresentationRead>>([]);

	const getSessionQuery = useSession(client.id, event.id, sessionEditSchedule?.id ?? "", { enabled: !!sessionEditSchedule });
	const getPresentationBySessionQuery = usePresentations(client.id, event.id, sessionEditSchedule?.id ?? "", {
		queryStrings: sortToQueryString([
			{ field: "startDate", sort: "asc" },
			{ field: "isWithoutTimeSlot", sort: "asc" },
		]),
		enabled: !!sessionEditSchedule,
	});

	const sessionSubmitSuccess = useCallback(() => {
		snackbar.enqueueSnackbar(t(`${component}.sessionSubmitSuccess`), { variant: "success" });
	}, [snackbar]);
	const presentationsSubmitSuccess = useCallback(() => {
		snackbar.enqueueSnackbar(t(`${component}.presentationsSubmitSuccess`), { variant: "success" });
	}, [snackbar]);

	const sessionUpdate = useSessionUpdate({ onSuccess: sessionSubmitSuccess });
	const sessionTagQuery = useTags(client.id, event.id, sessionEditSchedule?.id ?? "", { enabled: !!sessionEditSchedule });
	const presentationsBulkDateUpdate = useBulkUpdatePresentationsDatesUpdate({ onSuccess: presentationsSubmitSuccess });

	useEffect(() => {
		if (getPresentationBySessionQuery.data && sessionEditSchedule?.newDateTimeStart) {
			setPresentations(getPresentationBySessionQuery.data.data);
			const presentationsRecord = reschedulePresentations(getPresentationBySessionQuery.data.data, sessionEditSchedule?.newDateTimeStart).reduce(
				(acc, presentation) => {
					acc[presentation.id] = presentation;
					return acc;
				},
				{} as Record<string, PresentationEditSchedule>,
			);
			form.reset({
				session: sessionEditSchedule,
				presentations: presentationsRecord,
			});
		}
	}, [getPresentationBySessionQuery.data, sessionEditSchedule]);

	const form = useForm<FormPresentationsEdit>({
		mode: "onSubmit",
		defaultValues: { session: sessionEditSchedule, presentations: {} },
		resolver: useResolver(formPresentationEditValidationSchema(component)),
	});

	const handleSubmit = useCallback(
		async (formValues: FormPresentationsEdit) => {
			if (!getSessionQuery.data || !sessionEditSchedule?.id || !sessionEditSchedule?.newDateTimeStart) {
				throw new Error("Session not found");
			}
			if (
				getSessionQuery.data.startDate !== sessionEditSchedule.newDateTimeStart?.toISO() ||
				getSessionQuery.data.endDate !== sessionEditSchedule.newDateTimeEnd?.toISO()
			) {
				const sessionWrite = sessionEditScheduleToSessionWrite(formValues.session, getSessionQuery.data, sessionTagQuery.data?.data?.map((x) => x.id) ?? []);
				await sessionUpdate.mutateAsync({ clientId: client.id, eventId: event.id, sessionId: sessionEditSchedule.id ?? "", body: sessionWrite });
			}
			const presentationBulkDateUpdate = presentationEditScheduleToPresentationBulkDateUpdate(
				Object.values(formValues.presentations).filter((presentation) => presentation.newDateTimeStart?.isValid && presentation.newDateTimeEnd?.isValid),
				sessionEditSchedule.newDateTimeStart,
			);
			await presentationsBulkDateUpdate.mutateAsync({
				clientId: client.id,
				eventId: event.id,
				sessionId: sessionEditSchedule.id,
				body: presentationBulkDateUpdate,
			});

			props?.onClose?.();
		},
		[
			getSessionQuery.data,
			sessionUpdate,
			client.id,
			event.id,
			sessionEditSchedule?.id,
			presentationsBulkDateUpdate,
			sessionEditSchedule?.newDateTimeStart,
			sessionEditSchedule?.newDateTimeEnd,
			sessionTagQuery.data,
			props,
		],
	);

	if (!getPresentationBySessionQuery.isFetched || presentations.length === 0) {
		return null;
	}

	const handleSaveButtonClick = (): void => {
		void form.handleSubmit(handleSubmit)();
	};

	const isSessionMultiDays = () =>
		sessionEditSchedule?.newDateTimeStart &&
		sessionEditSchedule.newDateTimeEnd &&
		sessionEditSchedule.newDateTimeEnd.toISODate() !== sessionEditSchedule.newDateTimeStart.toISODate();

	if (isSessionMultiDays()) {
		return (
			<DialogAdvanced
				open={isOpen}
				onClose={onClose}
				fullWidth
				maxWidth="md"
				title={{ variant: "h6", children: t(`${component}.title`) }}
				buttonGroupEnd={{
					buttons: [
						{
							onClick: onClose,
							...getButton({ type: "ok" }),
						},
					],
				}}
			>
				<Typography> {t(`${component}.moreThanOneDayError`)}</Typography>
			</DialogAdvanced>
		);
	}

	return (
		<DialogAdvanced
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="md"
			title={{ variant: "h6", children: t(`${component}.title`) }}
			buttonGroupEnd={{
				buttons: [
					{ onClick: onClose, ...getButton({ type: "cancel" }) },
					{
						disabled: false,
						onClick: handleSaveButtonClick,
						...getButton({ type: "save" }),
					},
				],
			}}
		>
			<TitleLink
				titleProps={{ children: `${t("session")} - ` }}
				linkProps={{
					to: [ExternalRouting.Programme, client.key, event.key, EMenuPath.SESSIONS, sessionEditSchedule?.id].join("/"),
					children: <>{` ${getSessionQuery.data?.code ?? t("noCode")}`}</>,
				}}
			/>

			<FormProvider {...form}>
				<ScheduleUpdate
					title={getSessionQuery.data?.title ?? ""}
					code={getSessionQuery.data?.code ?? ""}
					oldDateBlockProps={{
						isDisabled: true,
						displayDate: "session.oldDateTimeStart",
						startDateField: "session.oldDateTimeStart",
						endDateField: "session.oldDateTimeEnd",
					}}
					newDateBlockProps={{
						displayDate: "session.newDateTimeStart",
						startDateField: "session.newDateTimeStart",
						endDateField: "session.newDateTimeEnd",
						defaultCalendarDate: sessionEditSchedule?.newDateTimeStart,
					}}
				/>

				{presentations.length > 0 && (
					<Box paddingTop={3}>
						<TitleDivider
							{...getTitleDivider({
								type: "sectionTitle",
								translation: t(`${component}.presentations.title`),
							})}
						/>
						{presentations.map((presentation, index) => (
							<Box key={uniqueId(index.toString())} paddingTop={2}>
								<ScheduleUpdate
									title={presentation.title ?? ""}
									code={presentation.code}
									isWithoutTimeSlot={presentation.isWithoutTimeSlot}
									oldDateBlockProps={{
										isDisabled: true,
										startDateField: `presentations.${presentation.id}.oldDateTimeStart`,
										endDateField: `presentations.${presentation.id}.oldDateTimeEnd`,
									}}
									newDateBlockProps={{
										startDateField: `presentations.${presentation.id}.newDateTimeStart`,
										endDateField: `presentations.${presentation.id}.newDateTimeEnd`,
									}}
								/>
							</Box>
						))}
					</Box>
				)}
			</FormProvider>
		</DialogAdvanced>
	);
};
