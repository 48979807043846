import { type PresentationWrite, postPresentation, postSpeaker } from "@api";
import { API_VERSION_QUERY_PARAM, type ParticipantWrite, type UseMutationArgs, postParticipant } from "@key4-front-library/core";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";

export interface CreatePresentationAndSpeakerFromContactArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: PresentationWrite;
	bodyParticipant: ParticipantWrite;
	qualityId: string;
	signal?: AbortSignal;
}

export const useCreatePresentationAndSpeakerFromContact = (
	args: UseMutationArgs<CreatePresentationAndSpeakerFromContactArgs, string> = {},
): UseMutationResult<string, Error, CreatePresentationAndSpeakerFromContactArgs> => {
	return useMutation<string, Error, CreatePresentationAndSpeakerFromContactArgs>({
		mutationFn: async (request: CreatePresentationAndSpeakerFromContactArgs) => {
			const { clientId, eventId, sessionId, qualityId, body, bodyParticipant, signal } = request;

			const presentationId = await postPresentation({
				clientId,
				eventId,
				sessionId,
				body,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
				signal,
			});

			const participantId = await postParticipant({
				clientId,
				eventId,
				body: bodyParticipant,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }],
				signal,
			});
			return postSpeaker({
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				body: { qualityId: qualityId !== "" ? qualityId : undefined, invitationStatus: "unsent" },
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});
		},
		...args,
	});
};

export interface CreatePresentationAndSpeakerFromParticipantArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
	body: PresentationWrite;
	qualityId: string;
	signal?: AbortSignal;
}

export const useCreatePresentationAndSpeakerFromParticipant = (
	args: UseMutationArgs<CreatePresentationAndSpeakerFromParticipantArgs, string> = {},
): UseMutationResult<string, Error, CreatePresentationAndSpeakerFromParticipantArgs> => {
	return useMutation<string, Error, CreatePresentationAndSpeakerFromParticipantArgs>({
		mutationFn: async (request: CreatePresentationAndSpeakerFromParticipantArgs) => {
			const { clientId, eventId, sessionId, qualityId, body, participantId, signal } = request;

			const presentationId = await postPresentation({
				clientId,
				eventId,
				sessionId,
				body,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
				signal,
			});

			return postSpeaker({
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				body: { qualityId: qualityId !== "" ? qualityId : undefined, invitationStatus: "unsent" },
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});
		},
		...args,
	});
};
