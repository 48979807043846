import { usePresentation, usePresentationUpdate, useSession } from "@api";
import {
	PresentationForm,
	type PresentationModalForm,
	isPresentationDateDisabled,
	presentationDefaultValues,
	presentationReadToPresentationForm,
	presentationSchema,
	typeContactToParticipantWrite,
} from "@components";
import { presentationFormToPresentationWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreatePresentationAndSpeakerFromContact, useCreatePresentationAndSpeakerFromParticipant } from "@hooks";
import { type CustomFieldForm, CustomFieldFormTabs, DialogAdvanced, getDialogAdvanced, useContextModule, useSnackbar } from "@key4-front-library/core";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface PresentationModalProps {
	sessionId: string;
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	id?: string;
}

export const PresentationModal = (props: PresentationModalProps) => {
	const component = "presentationModal";
	const { sessionId, isOpened, onClose, id, onCallbackSubmit } = props;

	const [isLoaded, setIsLoaded] = useState(false);
	const [isDatesDisabled, setIsDatesDisabled] = useState<boolean>();
	const [isHoursDisabled, setIsHoursDisabled] = useState<boolean>();

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const sessionQuery = useSession(client.id, event.id, sessionId);
	const presentationQuery = usePresentation(client.id, event.id, sessionId, id ?? "", { enabled: !!id });

	const form = useForm<CustomFieldForm<PresentationModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: presentationDefaultValues,
		resolver: zodResolver(presentationSchema(id)),
	});

	useEffect(() => {
		if (sessionQuery.data) {
			setIsDatesDisabled(isPresentationDateDisabled(sessionQuery.data));
			setIsHoursDisabled(!sessionQuery.data.startDate);
		}
	}, [sessionQuery.data]);

	useEffect(() => {
		if (sessionQuery.data && (presentationQuery.data || !id) && isLoaded) {
			form.reset(presentationReadToPresentationForm(sessionQuery.data, form.getValues("customFields"), presentationQuery.data));
		}
	}, [form.reset, presentationQuery.data, form.getValues, isLoaded, id, sessionQuery.data]);

	const handlePresentationSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: id ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const createPresentationAndSpeakerFromContact = useCreatePresentationAndSpeakerFromContact({ onSuccess: handlePresentationSubmit });
	const createPresentationAndSpeakerFromParticipant = useCreatePresentationAndSpeakerFromParticipant({ onSuccess: handlePresentationSubmit });
	const presentationEdit = usePresentationUpdate({ onSuccess: handlePresentationSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<PresentationModalForm>) => {
			if (id) {
				presentationEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId: sessionId,
					presentationId: id,
					body: presentationFormToPresentationWrite(data),
				});
				return;
			}
			if (data.participantOrContact?.category === "contacts") {
				createPresentationAndSpeakerFromContact.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					body: presentationFormToPresentationWrite(data),
					bodyParticipant: typeContactToParticipantWrite(data.participantOrContact),
					qualityId: data.qualityId,
				});
				return;
			}

			if (data.participantOrContact?.category === "participants") {
				createPresentationAndSpeakerFromParticipant.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					body: presentationFormToPresentationWrite(data),
					participantId: data.participantOrContact.id,
					qualityId: data.qualityId,
				});
				return;
			}
			snackbar.enqueue({ type: "unexpected" });
		},
		[
			client.id,
			event.id,
			id,
			presentationEdit,
			sessionId,
			createPresentationAndSpeakerFromContact,
			createPresentationAndSpeakerFromParticipant,
			snackbar.enqueue,
		],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<PresentationModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	if (isDatesDisabled === undefined || isHoursDisabled === undefined) {
		return undefined;
	}

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: id ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
		>
			<FormProvider {...form}>
				<CustomFieldFormTabs
					onIsLoaded={handleIsLoaded}
					module="programme"
					scope="presentation"
					businessForm={<PresentationForm isDatesDisabled={isDatesDisabled} isHoursDisabled={isHoursDisabled} isEditMode={!!id} />}
				/>
			</FormProvider>
		</DialogAdvanced>
	);
};
