import type { InjectSessionData, SessionModalForm } from "@components";
import { durationZod } from "@helpers";
import { type CustomFieldForm, customFieldsZod, dateNullableZod } from "@key4-front-library/core";
import { t } from "i18next";
import { z } from "zod";

export const sessionDefaultValues = (injectSessionData: InjectSessionData = {}): CustomFieldForm<SessionModalForm> => ({
	code: "",
	primaryTagId: "",
	organizedBy: "",
	title: "",
	description: "",
	isPrivate: false,
	expectedAudience: "",
	roomId: null,
	startDate: null,
	endDate: null,
	duration: "",
	status: "draft",
	publicationDate: null,
	customFields: {},
	shouldApplySessionTemplate: false,
	...injectSessionData,
});

const baseSchema = z.object({
	code: z.string(),
	primaryTagId: z.string(),
	organizedBy: z.string(),
	title: z.string(),
	description: z.string(),
	isPrivate: z.boolean(),
	expectedAudience: z.string(),
	roomId: z.string().nullable(),
	startDate: dateNullableZod,
	endDate: dateNullableZod,
	duration: durationZod(),
	status: z.string(),
	publicationDate: dateNullableZod,
	tags: z.record(z.string(), z.array(z.object({ value: z.string() }))),
	customFields: customFieldsZod,
	shouldApplySessionTemplate: z.boolean(),
});

export const sessionSchema = baseSchema.superRefine((data, ctx) => {
	if (data.startDate || data.endDate) {
		if (!data.startDate) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.required"),
				path: ["startDate"],
			});
		}

		if (!data.endDate) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.required"),
				path: ["endDate"],
			});
		}
	}
	if (data.startDate && data.startDate >= data.endDate) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("form.validation.endDateMustBeSuperiorToStartDate"),
			path: ["endDate"],
		});
	}
});
