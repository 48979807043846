import { type RoomRead, getRooms } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useRooms = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<RoomRead>> = {}): UseQueryResult<Paginated<RoomRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<Paginated<RoomRead>>({
		queryKey: [...QUERY_KEYS_PROGRAMME.rooms, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getRooms({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
