import { type TagRead, getChairQualities } from "@api";
import { API_VERSION_QUERY_PARAM, type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useChairQualities = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<TagRead>> = {}): UseQueryResult<Paginated<TagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.chairs, ...queryKeyPart, "Quality", "Get", "List", clientId, eventId, queryStrings],
		queryFn: ({ signal }) =>
			getChairQualities({
				clientId,
				eventId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};
