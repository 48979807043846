import ControllersApp from "@application/Controllers/ControllersApp";
import { EnumChairFormKey } from "@application/Enums/ChairEnum";
import HelpersApp from "@application/helpers";
import { CustomFieldHelper, FormHelper, UseFormHelper, queryFilters, useContextModule } from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabsOld";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import type { DtoSessionGetChair, SessionTemplatesRead } from "@key4-front-library/core/Dto";
import { ESieveOperator, EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readWholeFormByScope } = ControllersBo.CustomFieldController.useEntity();
	const { readListChairQualities } = ControllersApp.ChairController.useEntity();
	const { client, event } = useContextModule();
	const { read: readSession } = ControllersApp.SessionController.useEntity();

	const readWholeForm = (sessionId: string, chair?: DtoSessionGetChair) => {
		const { data, isFetching, refetch, isFetchedAfterMount } = useQuery({
			queryKey: ["chair", "form", chair?.participantId ?? "create"],
			queryFn: async (): Promise<{
				componentData: PropsFormTabs;
				useFormData: TypeUseFormListForms;
			}> => {
				let formsData = await readWholeFormByScope(EnumCustomFieldScope.CHAIR);
				const qualities = await readListChairQualities();
				const useFormData: TypeUseFormListForms = {};
				const componentData: PropsFormTabs = {
					tabs: [],
				};

				// CREATE CHAIR
				// If the modal is in creation mode, we don't want to show the "replacement" tab
				if (!chair?.participantId) {
					formsData = formsData.filter((tab) => {
						return tab.data.key !== EnumChairFormKey.REPLACEMENT;
					});
				}

				// #region 'Useform default values'
				// Add Forms / Sections useFormData
				UseFormHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);

				// Add Static fields useFormData
				HelpersApp.ChairHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData, qualities.length > 0);

				// Add Custom fields useFormData
				CustomFieldHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);
				// #endregion 'Useform default values'

				// #region 'Component data'
				// Add tabs and sections props
				FormHelper.mapFormTabsProps(formsData, componentData);

				// Add static fields props
				HelpersApp.ChairHelper.mapFormTabsPropsStaticFields(formsData, componentData, qualities, chair?.participantId);

				// Add custom fields props
				CustomFieldHelper.mapFormTabsPropsFields(formsData, componentData);
				// #endregion 'Component data'

				// #region 'Add default values in fields'
				// Create chair
				if (!chair?.participantId) {
					// Get the sessionTemplate if there is one
					const session = await readSession(sessionId);

					const tagId = session.tagTypes?.[0]?.tags?.[0]?.id;

					let sessionTemplatesAssociatedToTheSessionPrimaryTag: Array<SessionTemplatesRead> = [];
					if (tagId) {
						sessionTemplatesAssociatedToTheSessionPrimaryTag = await ControllersBo.SessionTemplatesController.getSessionTemplatesList(client.id, event.id, [
							...queryFilters(`primaryTagId${ESieveOperator.EQUALS}${tagId}`),
						]);
					}
					HelpersApp.ChairHelper.mapFormsTabsCreateChairDefaultValues(useFormData, sessionTemplatesAssociatedToTheSessionPrimaryTag[0], qualities);
				} else {
					// Edit chair

					// Add Static fields useFormData
					HelpersApp.ChairHelper.mapFormTabsEditUseFormDefaultValue(useFormData, chair);

					// Add Custom fields useFormData
					CustomFieldHelper.mapFormTabsEditUseFormDefaultValue(useFormData, chair.customFieldValues);
				}

				return { componentData, useFormData };
			},
		});
		return { data, isFetching, refetch, isFetchedAfterMount };
	};

	return { readWholeForm };
};

const ChairHook = {
	useEntity,
};

export default ChairHook;
