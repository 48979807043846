import { usePresentationSpeakers, usePresentations, useSessionV1 } from "@api";
import { SchedulerCardPopover, type SchedulerCardPopoverProps, sessionToSchedulerCardPopover } from "@components";
import { ESieveOperator, type PopoverProps, filtersToQueryString, paginationToQueryString, useContextModule } from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { useEffect, useState } from "react";

export interface SessionCardPopoverProps {
	sessionId: string;
	popover: PopoverProps;
}

/**
 * TODO
 * This component should be higher level (K4CalendarEvent) and should regroup API calls and mapper for card popover and content
 */
export const SessionCardPopover = (props: SessionCardPopoverProps): React.ReactNode => {
	const { sessionId, popover } = props;
	const { client, event } = useContextModule();
	const [schedulerCardPopover, setSchedulerCardPopover] = useState<SchedulerCardPopoverProps>();
	const theme = useTheme();

	const sessionQuery = useSessionV1(client.id, event.id, sessionId);
	const presentationsQuery = usePresentations(client.id, event.id, sessionId, {
		queryStrings: paginationToQueryString({
			pageSize: 4,
		}),
	});

	const speakersQuery = usePresentationSpeakers(client.id, event.id, {
		queryStrings: filtersToQueryString(
			[
				`presentationId${ESieveOperator.EQUALS}${presentationsQuery.data?.data.map((presentation) => presentation.id).join(ESieveOperator.OR_IN)}`,
				`invitationStatus${ESieveOperator.EQUALS}${["accepted", "waitingForAnswer"].join(ESieveOperator.OR_IN)}`,
			].join(ESieveOperator.AND),
		),
		enabled: !!presentationsQuery.data?.pagination?.totalCount && presentationsQuery.data.pagination.totalCount > 0,
	});

	useEffect(() => {
		if (sessionQuery?.data && presentationsQuery?.data) {
			setSchedulerCardPopover(
				sessionToSchedulerCardPopover({
					keys: [client.key, event.key, sessionId],
					session: sessionQuery.data,
					presentations: presentationsQuery.data.data,
					speakers: speakersQuery.data?.data,
					theme,
				}),
			);
		}
	}, [client, event, sessionId, sessionQuery?.data, presentationsQuery?.data, speakersQuery.data?.data, theme]);

	if (!schedulerCardPopover) {
		return undefined;
	}

	return (
		<HoverPopover {...popover}>
			<SchedulerCardPopover {...schedulerCardPopover} />
		</HoverPopover>
	);
};
