import { type SpeakerModalForm, getInvitationFacultyStatusesOptions } from "@components";
import {
	type ActionItem,
	AutoCompleteParticipantOrContact,
	FormControl,
	Grid,
	Select,
	TextField,
	TitleDivider,
	type TypographyProps,
	UserInformation,
	type UserInformationProps,
	getSelect,
	getTextField,
	getTitleDivider,
} from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import { t } from "i18next";
import { Controller, useFormContext } from "react-hook-form";

interface FacultyFormProps {
	isEditMode: boolean;
	userInformation?: UserInformationProps;
	qualitiesOptions: Array<ActionItem<TypographyProps>>;
}

export const FacultyForm = (props: FacultyFormProps): React.ReactNode => {
	const { isEditMode, userInformation, qualitiesOptions } = props;
	const component = "facultyForm";

	const theme = useTheme();
	const form = useFormContext<SpeakerModalForm, undefined>();

	return (
		<Grid container spacing={2} pt={2}>
			<Grid item xs={12}>
				{!isEditMode ? (
					<Controller
						control={form.control}
						name={"participantOrContact"}
						render={({ field, field: { onChange }, fieldState: { error } }) => (
							<AutoCompleteParticipantOrContact
								updateOtherFieldsAfterParticipantOrContactSelection={false}
								required={false}
								onChange={(val) => {
									onChange(val);
								}}
								error={error?.message}
								field={field}
							/>
						)}
					/>
				) : (
					<UserInformation {...userInformation} />
				)}
			</Grid>
			<FormControl
				name={"qualityId"}
				gridSize={{ xs: 6 }}
				render={(args) => <Select label={t(`${component}.qualityId`)} {...args} {...getSelect({ type: "default" }, qualitiesOptions)} />}
			/>
			<FormControl
				name={"invitationStatus"}
				gridSize={{ xs: 6 }}
				render={(args) => (
					<Select label={t(`${component}.invitationStatus`)} {...args} {...getSelect({ type: "bulletText" }, getInvitationFacultyStatusesOptions(theme))} />
				)}
			/>
			{isEditMode && (
				<>
					<Grid item xs={12}>
						<TitleDivider
							{...getTitleDivider({
								type: "sectionTitle",
								translation: t(`${component}.replacement`),
							})}
						/>
					</Grid>
					<FormControl
						gridSize={{ xs: 12 }}
						name={"replacementEmail"}
						render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementEmail`)} {...getTextField({})} />}
					/>
					<FormControl
						gridSize={{ xs: 12 }}
						name={"replacementFirstname"}
						render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementFirstname`)} {...getTextField({})} />}
					/>
					<FormControl
						gridSize={{ xs: 12 }}
						name={"replacementLastname"}
						render={(args) => <TextField {...args} disabled={true} label={t(`${component}.replacementLastname`)} {...getTextField({})} />}
					/>
					<FormControl
						gridSize={{ xs: 12 }}
						name={"replacementComment"}
						render={(args) => (
							<TextField {...args} disabled={true} label={t(`${component}.replacementComment`)} {...getTextField({ type: "textarea" })} minRows={5} />
						)}
					/>
				</>
			)}
		</Grid>
	);
};
