import { EMenuPath, ExternalRouting, type IDivider, type IMenuItem } from "@key4-front-library/core";

export type Menu =
	| "dashboard"
	| "sessions"
	| "scheduler"
	| "faculties"
	| "rooms"
	| "alerts"
	| "settings"
	| "advancedSettings"
	| "users"
	| "divider"
	| "support";

export const MenuItems: Record<Menu, IMenuItem | IDivider> = {
	dashboard: {
		translation: `old.programme.navigation.${EMenuPath.DASHBOARD}`,
		path: EMenuPath.DASHBOARD,
		icon: {
			prefix: "far",
			iconName: "chart-line-up",
		},
	},
	sessions: {
		translation: `old.programme.navigation.${EMenuPath.SESSIONS}`,
		path: EMenuPath.SESSIONS,
		icon: {
			prefix: "far",
			iconName: "clipboard-list",
		},
	},
	scheduler: {
		translation: `old.programme.navigation.${EMenuPath.SCHEDULER}`,
		path: EMenuPath.SCHEDULER,
		icon: {
			prefix: "far",
			iconName: "calendar-circle-plus",
		},
	},
	faculties: {
		translation: `old.programme.navigation.${EMenuPath.FACULTIES}`,
		path: EMenuPath.FACULTIES,
		icon: {
			prefix: "far",
			iconName: "user-group",
		},
	},
	rooms: {
		translation: `old.programme.navigation.${EMenuPath.ROOMS}`,
		path: EMenuPath.ROOMS,
		icon: {
			prefix: "far",
			iconName: "screen-users",
		},
	},
	alerts: {
		translation: `old.programme.navigation.${EMenuPath.ALERTS}`,
		path: EMenuPath.ALERTS,
		icon: {
			prefix: "far",
			iconName: "bell",
		},
	},
	settings: {
		translation: `old.programme.navigation.${EMenuPath.SETTINGS}`,
		path: EMenuPath.SETTINGS,
		icon: {
			prefix: "far",
			iconName: "gear",
		},
	},
	advancedSettings: {
		translation: `old.programme.navigation.${EMenuPath.ADVANCED_SETTINGS}`,
		path: EMenuPath.ADVANCED_SETTINGS,
		icon: {
			prefix: "far",
			iconName: "screwdriver-wrench",
		},
	},
	users: {
		translation: `old.programme.navigation.${EMenuPath.USERS}`,
		path: EMenuPath.USERS,
		icon: {
			prefix: "far",
			iconName: "users",
		},
	},
	support: {
		translation: `old.programme.navigation.${EMenuPath.SUPPORT}`,
		path: EMenuPath.SUPPORT,
		icon: {
			prefix: "far",
			iconName: "headset",
		},
		externalLink: ExternalRouting.Support,
		target: "_blank",
	},
	divider: { type: "divider" },
};

export default Menu;
