import { type TagTypeRead, getTagTypes } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useTagTypes = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<TagTypeRead>> = {}): UseQueryResult<Paginated<TagTypeRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<Paginated<TagTypeRead>>({
		queryKey: [...QUERY_KEYS_PROGRAMME.tagTypes, ...queryKeyPart, "Get", "List", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getTagTypes({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
