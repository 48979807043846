import { t } from "i18next";
import { toPairs } from "lodash";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import { EnumSpeakerFieldKey, EnumSpeakerFormKey, EnumSpeakerSectionKey } from "@application/Enums/SpeakerEnum";
import type { TypeSessionStaticFieldData, TypeSpeakerStaticFieldData } from "@application/Types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	CustomFieldHelper,
	type DtoChairGetQuality,
	type DtoSessionGetSpeaker,
	type DtoSpeakerGetQuality,
	type DtoSpeakerWrite,
	EnumCustomFieldKind,
	EnumFormControlKind,
	EnumParticipantStatusInvitation,
	type SessionTemplatesRead,
	type TypeContact,
	type TypeCustomFieldForm,
	type TypeParticipant,
	type TypeUseFormFieldValues,
	type TypeUseFormListForms,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabsOld";

const getResolver: any = (useFormData: TypeUseFormListForms | undefined, speakerId?: string) => {
	const resolver: any = {};
	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	toPairs(useFormData).forEach((form) => {
		const formId = form[0];
		if (form[1] === undefined) return;
		const formResolver: any = {};
		toPairs(form[1]).forEach((section) => {
			const sectionId = section[0];
			if (section[1] === undefined) return;
			const sectionResolver: any = {};
			toPairs(section[1]).forEach((field) => {
				const fieldId = field[0];
				const fieldValue = field[1];
				switch (fieldId) {
					case EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT:
						if (!speakerId) {
							sectionResolver[fieldId] = yup
								.object()
								.nullable()
								.required(t("old.common.formControl.error.required") ?? "");
						}

						break;
					case EnumSpeakerFieldKey.QUALITY:
						sectionResolver[fieldId] = yup.string().nullable();
						break;
				}

				CustomFieldHelper.setupResolver(t, sectionResolver, section[1], fieldId, fieldValue);
			});

			if (Object.keys(sectionResolver).length > 0) {
				formResolver[sectionId] = yup.object(sectionResolver);
			}
		});
		if (Object.keys(formResolver).length > 0) {
			resolver[formId] = yup.object(formResolver);
		}
	});

	return yupResolver(yup.object(resolver));
};

const mapUseFormToDtoSpeakerWrite = (useFormData: TypeUseFormListForms, speakerPost: DtoSpeakerWrite) => {
	toPairs(useFormData).forEach((formObject) => {
		if (formObject[1] === undefined) return;
		toPairs(formObject[1]).forEach((sectionObject) => {
			if (sectionObject[1] === undefined) return;
			toPairs(sectionObject[1]).forEach((fieldObject) => {
				const fieldId = fieldObject[0];
				const fieldValue = fieldObject[1];

				// STATIC FIELDS
				switch (fieldId) {
					case EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT:
						speakerPost.participantId = (fieldValue as TypeParticipant | TypeContact).id;
						return;
					case EnumSpeakerFieldKey.QUALITY:
						speakerPost.qualityIds = fieldValue ? ([fieldValue] as Array<string>) : [];
						return;
					case EnumSpeakerFieldKey.INVITATION:
						speakerPost.invitationStatus = fieldValue as EnumParticipantStatusInvitation;
						return;
				}

				// CUSTOM FIELDS
				if (fieldId === "customFieldValues") {
					if (!fieldValue) return;

					toPairs(fieldValue as TypeUseFormFieldValues).forEach((customField) => {
						const customfieldId: string = customField[0];
						const customfieldValue = customField[1];
						const customFieldMetadataValue = sectionObject[1].metadata![customfieldId].customField!;
						switch (sectionObject[1].metadata![customfieldId].customField!.kind) {
							case EnumCustomFieldKind.SELECT:
							case EnumCustomFieldKind.RADIO:
								const val = typeof customfieldValue === "string" ? [customfieldValue] : [];
								const isInherited: boolean = customFieldMetadataValue.isInherited;
								speakerPost.customFieldValues![customfieldId] = {
									isCustomized: isInherited,
									value: isInherited ? customFieldMetadataValue.customDefault.value : val,
									customizedValue: JSON.stringify(val) !== JSON.stringify(customFieldMetadataValue.customDefault.value) && isInherited ? val : null,
								};
								return;
							default:
								const valDefault: string | boolean | Array<string> | null | undefined =
									typeof customfieldValue === "number" ? `${customfieldValue}` : (customfieldValue as string | boolean | Array<string> | null | undefined);

								speakerPost.customFieldValues![customfieldId] = {
									isCustomized: customFieldMetadataValue.isInherited,
									value: customFieldMetadataValue.isInherited ? customFieldMetadataValue.customDefault.value : valDefault,
									customizedValue: customFieldMetadataValue.customDefault.value !== valDefault && customFieldMetadataValue.isInherited ? valDefault : null,
								};
								return;
						}
					});
				}
			});
		});
	});
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	qualities: Array<DtoSpeakerGetQuality>,
	participantId?: string,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumSpeakerFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumSpeakerSectionKey) as Array<string>).includes(section.data.key)
			) {
				toPairs(
					ConfigurationsApp.SpeakerConfiguration.staticListFormControlsObject[form.data.key as EnumSpeakerFormKey][section.data.key as EnumSpeakerSectionKey],
				).forEach((val: [string, TypeSpeakerStaticFieldData]) => {
					let formControl = val[1].component;
					const name = [form.data.id, section.data.id, formControl.id].join(".");
					formControl.propsComponent.label = t(formControl.propsComponent.label);
					switch (formControl.kind) {
						case EnumFormControlKind.SELECT:
							formControl.propsComponent.items = qualities.map((quality) => {
								return {
									key: quality.id,
									label: quality.label ?? "",
								};
							});
							break;

						case EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT:
							participantId && (formControl = undefined!);
							break;

						case EnumFormControlKind.SELECT_BULLET_COLOR:
							formControl.propsComponent.items = formControl.propsComponent.items.map((item) => {
								return {
									...item,
									label: t(item.label),
								};
							});
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				});
			}
		}
	}
};

const mapFormTabsUseFormDefaultValue = (formsData: Array<TypeCustomFieldForm>, useFormData: TypeUseFormListForms, shouldDisplayQualities: boolean) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			if (
				(Object.values(EnumSpeakerFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumSpeakerSectionKey) as Array<string>).includes(section.data.key)
			) {
				toPairs(
					ConfigurationsApp.SpeakerConfiguration.staticListFormControlsObject[form.data.key as EnumSpeakerFormKey][section.data.key as EnumSpeakerSectionKey],
				).forEach((val: [string, TypeSessionStaticFieldData]) => {
					useFormData[form.data.id][section.data.id][val[0]] = val[1].value;

					useFormData[form.data.id][section.data.id].metadata![val[0] as EnumSpeakerFieldKey] = {
						visibility: (val[0] as EnumSpeakerFieldKey) === EnumSpeakerFieldKey.QUALITY ? shouldDisplayQualities : true,
					};
				});
			}
		}
	}
};

const mapFormTabsEditUseFormDefaultValue = (useFormData: TypeUseFormListForms, speaker: DtoSessionGetSpeaker) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId as EnumSpeakerFieldKey) {
					case EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT:
						useFormData[formId][sectionId][fieldId] = speaker.participantId;
						break;
					case EnumSpeakerFieldKey.QUALITY:
						useFormData[formId][sectionId][fieldId] = speaker.qualities?.[0]?.id ?? null;
						break;
					case EnumSpeakerFieldKey.INVITATION:
						useFormData[formId][sectionId][fieldId] = speaker.invitationStatus;
						break;
					case EnumSpeakerFieldKey.EMAIL:
						useFormData[formId][sectionId][fieldId] = speaker.replacement?.email ?? null;
						break;
					case EnumSpeakerFieldKey.FIRSTNAME:
						useFormData[formId][sectionId][fieldId] = speaker.replacement?.firstname ?? null;
						break;
					case EnumSpeakerFieldKey.LASTNAME:
						useFormData[formId][sectionId][fieldId] = speaker.replacement?.lastname ?? null;
						break;
					case EnumSpeakerFieldKey.COMMENTS:
						useFormData[formId][sectionId][fieldId] = speaker.replacement?.comment ?? null;
						break;
					default:
				}
			});
		});
	});
};

const mapFormsTabsCreateSpeakerDefaultValues = (
	useFormData: TypeUseFormListForms,
	sessionTemplate?: SessionTemplatesRead,
	qualities?: Array<DtoChairGetQuality>,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId) {
					case EnumSpeakerFieldKey.INVITATION:
						useFormData[formId][sectionId][fieldId] = sessionTemplate?.speakerInvitationStatus ?? EnumParticipantStatusInvitation.UNSENT;
						break;

					case EnumSpeakerFieldKey.QUALITY:
						useFormData[formId][sectionId][fieldId] = qualities?.[0]?.id ?? null;
						break;
				}
			});
		});
	});
};

const SpeakerHelper = {
	getResolver,
	mapFormTabsPropsStaticFields,
	mapFormTabsEditUseFormDefaultValue,
	mapUseFormToDtoSpeakerWrite,
	mapFormTabsUseFormDefaultValue,
	mapFormsTabsCreateSpeakerDefaultValues,
};

export default SpeakerHelper;
