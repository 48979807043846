import type { SessionTemplateRead } from "@api";
import type { SessionModalForm } from "@components";
import { durationISOToString } from "@mappers";
import type { UseFormReturn } from "react-hook-form";

export const overrideWithSessionTemplate = (form: UseFormReturn<SessionModalForm, undefined, undefined>, sessionTemplate: SessionTemplateRead) => {
	form.setValue("status", sessionTemplate.status);
	form.setValue("duration", durationISOToString(sessionTemplate.duration) ?? "");
	// TODO rajouter heures de template quand il y aura les TimePickers
	form.setValue("isPrivate", sessionTemplate.isPrivate);
	form.setValue("shouldApplySessionTemplate", true);
};
