import { type TagRead, getTags } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideVersion2 } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useTags = (
	clientId: string,
	eventId: string,
	tagTypeId: string,
	args: UseQueryArgs<Paginated<TagRead>> = {},
): UseQueryResult<Paginated<TagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery<Paginated<TagRead>>({
		queryKey: [...QUERY_KEYS_PROGRAMME.tags, ...queryKeyPart, "Get", "List", clientId, eventId, tagTypeId, queryStringOverride],
		queryFn: ({ signal }) => getTags({ clientId, eventId, tagTypeId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
