import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { TSessionDisplay } from "@domain/interfaces/session.interface";
import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import {
	ButtonIcon,
	ConfirmationModal,
	CustomFieldsDisplayer,
	EConfirmationModalAction,
	ErrorAPI,
	Services,
	ThemeColors,
	useContextModule,
	useToggle,
} from "@key4-front-library/core";
import { Box, Grid, Stack } from "@mui/material";

import { useSessionToken, useSessionView } from "../../../../Hooks/Session";
import { dtoTokenToExternalLink } from "./GeneralView.mapper";

export const GeneralView = (): React.ReactNode => {
	const { sessionId = "" } = useParams();
	const [sessionDisplay, setSessionDisplay] = useState<TSessionDisplay>();
	const [externalLink, setExternalLink] = useState<string>();
	const [sessionTokenId, setSessionTokenId] = useState<string>();
	const { event, client } = useContextModule();
	const { isToggle: isOpenDeleteConfirmationModal, toggle: toggleOpenDeleteConfirmationModal } = useToggle();
	const sessionViewQuery = useSessionView(client.id, event.id, sessionId);

	const sessionTokenQuery = useSessionToken(client.id, event.id, sessionId);

	const handleActionClick = async () => {
		if (!sessionTokenId) {
			console.error("sessionTokenId not set");
			return;
		}
		await Services.Events.Programme.TokenService.revokeToken(client.id, event.id, sessionTokenId);
		sessionTokenQuery.refetch();
		toggleOpenDeleteConfirmationModal();
	};

	useEffect(() => {
		if (sessionViewQuery.data && !(sessionViewQuery.data instanceof ErrorAPI)) {
			setSessionDisplay(sessionViewQuery.data.sessionDisplay);
		}
	}, [sessionViewQuery]);

	useEffect(() => {
		if (sessionTokenQuery.data?.[0]) {
			setExternalLink(dtoTokenToExternalLink(sessionTokenQuery.data[0], event.domain));
			setSessionTokenId(sessionTokenQuery.data[0].id);
		}
	}, [sessionTokenQuery]);

	if (!sessionDisplay) return undefined;

	return (
		<>
			<ConfirmationModal
				open={isOpenDeleteConfirmationModal}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={toggleOpenDeleteConfirmationModal}
				handleAction={handleActionClick}
				maxWidth={"sm"}
			/>
			<Stack spacing={2}>
				<IconCard title={t("old.programme.sessionDetails.general.label")} icon={"clipboard-list"}>
					<Grid container spacing={2}>
						<IconCardItem label={t("old.programme.sessionDetails.general.id")} value={sessionDisplay.key} />
						{sessionDisplay.code ? <IconCardItem label={t("old.programme.sessionDetails.general.code")} value={sessionDisplay.code} /> : <Grid item xs={6} />}
						{sessionDisplay.description && (
							<IconCardItem
								gridSize={{ xs: 12, md: 12, lg: 12 }}
								label={t("old.programme.sessionDetails.description.label")}
								value={sessionDisplay.description}
							/>
						)}
						<Grid item alignItems={"center"}>
							<Stack direction="row" alignItems={"center"}>
								<IconCardItem
									label={t("old.programme.sessionDetails.general.externalLink")}
									value={externalLink}
									isValueALink={true}
									gridSize={{ xs: 12, md: 12, lg: 12 }}
								/>
								{externalLink && (
									<Box mt={2}>
										<ButtonIcon
											aria-label="actions"
											iconName={"trash"}
											iconSize="sm"
											iconColor={ThemeColors.error.main}
											onClick={toggleOpenDeleteConfirmationModal}
										/>
									</Box>
								)}
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<CustomFieldsDisplayer formOrder={0} sectionOrder={0} subSectionOrder={0} values={sessionDisplay.customFieldValues} />
						</Grid>
						<Grid item xs={12}>
							<CustomFieldsDisplayer formOrder={0} sectionOrder={1} subSectionOrder={1} values={sessionDisplay.customFieldValues} />
						</Grid>
					</Grid>
				</IconCard>
			</Stack>
		</>
	);
};
