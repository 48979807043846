import { useSpeakerQualities } from "@api";
import { FacultyForm, type SpeakerModalForm } from "@components";
import { type ActionItem, type TypographyProps, type UserInformationProps, useContextModule } from "@key4-front-library/core";
import { tagsReadToSelectItems } from "@mappers";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface SpeakerFormProps {
	isEditMode: boolean;
	userInformation?: UserInformationProps;
}

export const SpeakerForm = (props: SpeakerFormProps): React.ReactNode => {
	const { isEditMode, userInformation } = props;

	const { client, event } = useContextModule();

	const [speakerQualitiesOptions, setSpeakerQualitiesOptions] = useState<Array<ActionItem<TypographyProps>>>([]);
	const form = useFormContext<SpeakerModalForm, undefined>();
	const speakerQualitiesQuery = useSpeakerQualities(client.id, event.id);

	useEffect(() => {
		if (speakerQualitiesQuery?.data?.data) {
			setSpeakerQualitiesOptions(tagsReadToSelectItems(speakerQualitiesQuery?.data?.data));
			if (!isEditMode) {
				form.setValue("qualityId", speakerQualitiesQuery?.data?.data?.[0]?.id ?? "");
			}
		}
	}, [speakerQualitiesQuery?.data?.data, form.setValue, isEditMode]);

	return <FacultyForm isEditMode={isEditMode} qualitiesOptions={speakerQualitiesOptions} userInformation={userInformation} />;
};
