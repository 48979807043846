import { useSpeakerQualities } from "@api";
import type { PresentationModalForm } from "@components";
import {
	type ActionItem,
	Alert,
	AutoCompleteParticipantOrContact,
	DatePicker,
	FormControl,
	Grid,
	Select,
	Stack,
	TextField,
	TimePicker,
	type TypographyProps,
	getSelect,
	getTextField,
	useContextModule,
} from "@key4-front-library/core";
import { tagsReadToSelectItems } from "@mappers";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface PresentationFormProps {
	isEditMode: boolean;
	isDatesDisabled: boolean;
	isHoursDisabled: boolean;
}

export const PresentationForm = (props: PresentationFormProps): React.ReactNode => {
	const { isEditMode, isDatesDisabled, isHoursDisabled } = props;
	const component = "presentationForm";

	const { client, event } = useContextModule();

	const [speakerQualitiesOptions, setSpeakerQualitiesOptions] = useState<Array<ActionItem<TypographyProps>>>([]);
	const form = useFormContext<PresentationModalForm, undefined>();

	const speakerQualitiesQuery = useSpeakerQualities(client.id, event.id);

	useEffect(() => {
		if (speakerQualitiesQuery?.data?.data) {
			setSpeakerQualitiesOptions(tagsReadToSelectItems(speakerQualitiesQuery?.data?.data));
			if (!isEditMode) {
				form.setValue("qualityId", speakerQualitiesQuery?.data?.data?.[0].id ?? "");
			}
		}
	}, [speakerQualitiesQuery?.data?.data, form.setValue, isEditMode]);

	return (
		<Grid container spacing={2} pt={2}>
			<FormControl name={"code"} gridSize={{ xs: 4 }} render={(args) => <TextField {...args} label={t(`${component}.code`)} {...getTextField({})} />} />
			<FormControl name={"title"} render={(args) => <TextField {...args} label={t(`${component}.title`)} {...getTextField({})} />} />
			<FormControl
				name={"description"}
				render={(args) => <TextField {...args} label={t(`${component}.description`)} {...getTextField({ type: "textarea" })} minRows={5} />}
			/>
			{isHoursDisabled && (
				<Grid item xs={12}>
					<Stack>
						<Alert severity="warning">{t(`${component}.alert.timeSlotDisabled`)}</Alert>
					</Stack>
				</Grid>
			)}
			<FormControl
				name={"startDate"}
				gridSize={{ xs: 4 }}
				render={(args) => <DatePicker {...args} disabled={isDatesDisabled} label={t(`${component}.startDate`)} />}
			/>
			<FormControl
				name={"startHour"}
				gridSize={{ xs: 4 }}
				render={(args) => <TimePicker {...args} disabled={isHoursDisabled} label={t(`${component}.startHour`)} />}
			/>
			<Grid item xs={4} />
			<FormControl
				name={"endDate"}
				gridSize={{ xs: 4 }}
				render={(args) => <DatePicker {...args} disabled={isDatesDisabled} label={t(`${component}.endDate`)} />}
			/>
			<FormControl
				name={"endHour"}
				gridSize={{ xs: 4 }}
				render={(args) => <TimePicker {...args} disabled={isHoursDisabled} label={t(`${component}.endHour`)} />}
			/>
			<FormControl name={"duration"} gridSize={{ xs: 4 }} render={(args) => <TextField {...args} label={t(`${component}.duration`)} {...getTextField({})} />} />

			{!isEditMode && (
				<Grid item xs={12} lg={9}>
					<Controller
						control={form.control}
						name={"participantOrContact"}
						render={({ field, field: { onChange }, fieldState: { error } }) => (
							<AutoCompleteParticipantOrContact
								updateOtherFieldsAfterParticipantOrContactSelection={false}
								required={!isEditMode}
								onChange={(val) => {
									onChange(val);
								}}
								error={error?.message}
								field={field}
							/>
						)}
					/>
				</Grid>
			)}
			{!isEditMode && (
				<FormControl
					name={"qualityId"}
					gridSize={{ xs: 3 }}
					render={(args) => <Select label={t(`${component}.qualityId`)} {...args} {...getSelect({ type: "default" }, speakerQualitiesOptions)} />}
				/>
			)}
		</Grid>
	);
};
