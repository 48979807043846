import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import SettingsClashesSkeleton from "@application/components/settings/scheduler/clashes/SettingsClashesSkeleton";
import SettingsClashesView from "@application/components/settings/scheduler/clashes/SettingsClashesView";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import type { ISettingsClashesModel } from "@domain/model/settings.clashes.model";
import { Culture, Cultures, Services, useAuthenticationContext, useContextModule, useSnackBarHook } from "@key4-front-library/core";

export const Clashes = () => {
	const { client, event } = useContextModule();

	const { sendError } = useSnackBarHook();

	const { user } = useAuthenticationContext();

	const [culture, setCulture] = useState<string>(Culture[0].culture);
	const [settingsClashesData, setSettingsClashesData] = useState<ISettingsClashesObject>();
	const [isLoadingSettingsClashes, setIsLoadingSettingsClashes] = useState<boolean>(true);

	const fetchSettingsClashes = useCallback(
		async (clientId: string, eventId: string) => {
			await Services.Events.Programme.ClashesService.getSettings(clientId, eventId)
				.then((_data) => {
					remapAndSetSettingsClashes(_data);
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				})
				.finally(() => {
					setIsLoadingSettingsClashes(false);
				});
		},
		[sendError],
	);

	const remapAndSetSettingsClashes = (_settingsClashesModel: any) => {
		const settingsClashesObject = SettingsFactory.TransformSettingsClashesDataForReading({
			settings: _settingsClashesModel,
			culture,
		});
		setSettingsClashesData(settingsClashesObject);
	};

	const handleSaveClashesSettings = useCallback(
		(_data: ISettingsClashesObject) => {
			const updateData = async (clientId: string, eventId: string, settingsClashesModel: ISettingsClashesModel) => {
				await Services.Events.Programme.ClashesService.putSettings(clientId, eventId, settingsClashesModel)
					.then(() => {
						setIsLoadingSettingsClashes(true);
						fetchSettingsClashes(clientId, eventId);
					})
					.catch(() => {
						sendError(t("old.common.errors.generic"));
					})
					.finally(() => {
						setIsLoadingSettingsClashes(false);
					});
			};

			const settingsClashesModel = SettingsFactory.TransformSettingsClashesDataForWriting({
				settings: _data,
				culture,
			});

			setIsLoadingSettingsClashes(true);
			updateData(client.id, event.id, settingsClashesModel);
		},
		[client.id, culture, event.id, fetchSettingsClashes, sendError],
	);

	useEffect(() => {
		const codeCulture = Cultures[user.locale].locale;
		setCulture(codeCulture);
	}, [user.locale]);

	useEffect(() => {
		setIsLoadingSettingsClashes(true);
		fetchSettingsClashes(client.id, event.id);
	}, [event.id, client.id]);

	return (
		<>
			{!isLoadingSettingsClashes ? (
				settingsClashesData && <SettingsClashesView culture={culture} settings={settingsClashesData} saveSettings={handleSaveClashesSettings} />
			) : (
				<SettingsClashesSkeleton />
			)}
		</>
	);
};
