import { MINUTE_DURATION_REGEX, NUMBERS_REGEX } from "@key4-front-library/core";
import { t } from "i18next";
import { z } from "zod";

export const durationZod = () =>
	z.string().refine(
		(val) => {
			if (!val) {
				return true;
			}

			const durationSplitted = val.split(":");

			if (durationSplitted.length !== 2) {
				return false;
			}

			return durationSplitted[0].match(NUMBERS_REGEX) !== null && durationSplitted[1].match(MINUTE_DURATION_REGEX);
		},
		{ message: t("old.common.validation.durationWrongFormat") },
	);
