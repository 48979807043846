import type { PresentationRead, PresentationWrite, SessionRead } from "@api";
import type { PresentationModalForm } from "@components";
import {
	type CustomFieldForm,
	type CustomFieldsValuesForm,
	type ParticipantWrite,
	type TypeContact,
	customFieldValuesReadToCustomFieldsValuesForm,
	customFieldsValuesFormToCustomFieldValuesRead,
	dateTimeToISO,
	emptyToUndefined,
	getDateTime,
	isoDurationToString,
	stringISOToDateTimeNullable,
	stringToIsoDuration,
} from "@key4-front-library/core";

export const presentationFormToPresentationWrite = (form: CustomFieldForm<PresentationModalForm>): PresentationWrite => {
	return {
		code: emptyToUndefined(form.code),
		startDate: form.startHour && form.startDate ? dateTimeToISO(getDateTime(form.startDate, form.startHour)) : undefined,
		endDate: form.endHour && form.endDate ? dateTimeToISO(getDateTime(form.endDate, form.endHour)) : undefined,
		title: form.title,
		description: emptyToUndefined(form.description),
		duration: form.duration !== "" ? stringToIsoDuration(form.duration) : undefined,
		isWithoutTimeSlot: false,
		customFieldValues: customFieldsValuesFormToCustomFieldValuesRead(form.customFields),
	};
};

export const presentationReadToPresentationForm = (
	session: SessionRead,
	customFields: CustomFieldsValuesForm,
	presentation?: PresentationRead,
): CustomFieldForm<PresentationModalForm> => ({
	code: presentation?.code ?? "",
	title: presentation?.title ?? "",
	description: presentation?.description ?? "",
	startDate: presentation?.startDate ? stringISOToDateTimeNullable(presentation?.startDate) : stringISOToDateTimeNullable(session?.startDate),
	startHour: stringISOToDateTimeNullable(presentation?.startDate),
	endDate: presentation?.endDate ? stringISOToDateTimeNullable(presentation?.endDate) : stringISOToDateTimeNullable(session?.endDate),
	endHour: stringISOToDateTimeNullable(presentation?.endDate),
	duration: isoDurationToString(presentation?.duration),
	customFields: customFieldValuesReadToCustomFieldsValuesForm(customFields, presentation?.customFieldValues),
	participantOrContact: null,
	qualityId: "",
});

export const typeContactToParticipantWrite = (contact: TypeContact): ParticipantWrite => ({
	email: contact.email,
	firstname: contact.firstname,
	lastname: contact.lastname,
	invitationStatus: "unsent",
});
