import { type SpeakerWrite, postSpeaker } from "@api";
import { API_VERSION_QUERY_PARAM, type ParticipantWrite, type UseMutationArgs, postParticipant } from "@key4-front-library/core";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";

export interface CreateSpeakerFromContactArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	bodyParticipant: ParticipantWrite;
	body: SpeakerWrite;
	signal?: AbortSignal;
}

export const useCreateSpeakerFromContact = (
	args: UseMutationArgs<CreateSpeakerFromContactArgs, string> = {},
): UseMutationResult<string, Error, CreateSpeakerFromContactArgs> => {
	return useMutation<string, Error, CreateSpeakerFromContactArgs>({
		mutationFn: async (request: CreateSpeakerFromContactArgs) => {
			const { clientId, eventId, sessionId, body, presentationId, bodyParticipant, signal } = request;

			const participantId = await postParticipant({
				clientId,
				eventId,
				body: bodyParticipant,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }],
				signal,
			});
			return postSpeaker({
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				body,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});
		},
		...args,
	});
};
