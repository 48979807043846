import type { SessionTemplateRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "session-templates", id }];

interface GetSessionTemplatesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSessionTemplates = async (args: GetSessionTemplatesArgs): Promise<Paginated<SessionTemplateRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};
