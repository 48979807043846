import { DateBlock, type DateBlockProps } from "@application/components/Molecules";
import { FadeInWhenVisible, Icon, Typography, lineEllipsis } from "@key4-front-library/core";
import { Grid } from "@mui/material";
import { t } from "i18next";
import type { ReactNode } from "react";

export type ScheduleUpdateProps = {
	title?: string;
	code?: string;
	isWithoutTimeSlot?: boolean;
	oldDateBlockProps: DateBlockProps;
	newDateBlockProps: DateBlockProps;
};

export const ScheduleUpdate = (props: ScheduleUpdateProps): ReactNode => {
	const { title, code, isWithoutTimeSlot, oldDateBlockProps, newDateBlockProps } = props;

	const component = "scheduleUpdate";

	const formatTitleCode = (title?: string | null, code?: string | null): string => {
		if (!!title && !!code) {
			return `${code} - ${title}`;
		}
		if (title?.length) {
			return title;
		}
		if (code) {
			return code;
		}
		return t(`${component}.noTitleNoCode`);
	};

	return (
		<FadeInWhenVisible>
			<>
				{title !== undefined && (
					<Typography variant={"body1bold"} sx={lineEllipsis({ line: 1 })}>
						{formatTitleCode(title, code)}
					</Typography>
				)}
				{isWithoutTimeSlot ? (
					<Typography variant={"body1"} paddingTop={1}>
						{t(`${component}.withoutTimeSlot`)}
					</Typography>
				) : (
					<Grid container spacing={2} alignItems={"center"}>
						<DateBlock {...oldDateBlockProps} />
						<Grid item xs={1} textAlign={"center"}>
							<Icon name={"arrow-right"} />
						</Grid>
						<DateBlock {...newDateBlockProps} />
					</Grid>
				)}
			</>
		</FadeInWhenVisible>
	);
};
