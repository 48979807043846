import type { FacultyInvitationStatus } from "@api";
import { getFacultyStatus } from "@configurations";
import type { ActionItem, BulletTextProps } from "@key4-front-library/core";
import type { Theme } from "@mui/material";

export const getInvitationFacultyStatusesOptions = (theme: Theme): Array<ActionItem<BulletTextProps>> => {
	const facultyInvitationStatuses = getFacultyStatus(theme);
	return Object.keys(facultyInvitationStatuses).map((facultyStatus) => {
		const status = facultyInvitationStatuses[facultyStatus as FacultyInvitationStatus];
		return {
			value: facultyStatus,
			name: status.name,
			component: {
				bulletColor: status.color,
				text: status.name,
				typography: { variant: "body1" },
			},
		};
	});
};
