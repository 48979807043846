import { type ChairWrite, postChair } from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type DtoFaculty,
	type ParticipantWrite,
	QUERY_KEYS_PROGRAMME,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePagined,
	type UseMutationArgs,
	postParticipant,
} from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export interface CreateChairFromContactArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	bodyParticipant: ParticipantWrite;
	body: ChairWrite;
	signal?: AbortSignal;
}

export const useCreateChairFromContact = (
	args: UseMutationArgs<CreateChairFromContactArgs, string> = {},
): UseMutationResult<string, Error, CreateChairFromContactArgs> => {
	return useMutation<string, Error, CreateChairFromContactArgs>({
		mutationFn: async (request: CreateChairFromContactArgs) => {
			const { clientId, eventId, sessionId, body, bodyParticipant, signal } = request;

			const participantId = await postParticipant({
				clientId,
				eventId,
				body: bodyParticipant,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }],
				signal,
			});
			return postChair({
				clientId,
				eventId,
				sessionId,
				participantId,
				body,
				queryStrings: [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }],
			});
		},
		...args,
	});
};

interface Args {
	clientId: string;
	sessionId: string;
	eventId: string;
	queryStrings?: TypeApiQueryString[];
}

export const useChairs = (args: Args): UseQueryResult<TypeApiResponsePagined<Array<DtoFaculty>>> => {
	const { clientId, sessionId, eventId, queryStrings } = args;
	return useQuery({
		queryKey: [QUERY_KEYS_PROGRAMME.chairs, "Token", clientId, eventId, sessionId],
		queryFn: async () => await Services.Events.Programme.SessionsService.getListPaginedChair(clientId, eventId, sessionId, queryStrings),
	});
};
