import { type SpeakerRead, type SpeakerWrite, getPresentationSpeaker, getPresentationSpeakers, postSpeaker, putSpeaker } from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type Paginated,
	QUERY_KEYS_PROGRAMME,
	type UseMutationArgs,
	type UseQueryArgs,
	overrideQueryParams,
} from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const usePresentationSpeakers = (
	clientId: string,
	eventId: string,
	args: UseQueryArgs<Paginated<SpeakerRead>> = {},
): UseQueryResult<Paginated<SpeakerRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.speakers, ...queryKeyPart, "Get", "List", clientId, eventId, queryStrings],
		queryFn: ({ signal }) =>
			getPresentationSpeakers({
				clientId,
				eventId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const usePresentationSpeaker = (
	clientId: string,
	eventId: string,
	sessionId: string,
	presentationId: string,
	participantId: string,
	args: UseQueryArgs<SpeakerRead> = {},
): UseQueryResult<SpeakerRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.speakers, ...queryKeyPart, "Get", clientId, eventId, sessionId, presentationId, participantId, queryStrings],
		queryFn: ({ signal }) =>
			getPresentationSpeaker({
				clientId,
				eventId,
				sessionId,
				presentationId,
				participantId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export interface SpeakerCreateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
	signal?: AbortSignal;
}

export const useSpeakerCreate = (args: UseMutationArgs<SpeakerCreateRequest, string>): UseMutationResult<string, Error, SpeakerCreateRequest> => {
	return useMutation({
		mutationFn: (request: SpeakerCreateRequest) => postSpeaker(request),
		...args,
	});
};

export interface SpeakerUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
	signal?: AbortSignal;
}

export const useSpeakerUpdate = (args: UseMutationArgs<SpeakerUpdateRequest>): UseMutationResult<boolean, Error, SpeakerUpdateRequest> => {
	return useMutation({
		mutationFn: (request: SpeakerUpdateRequest) => putSpeaker(request),
		...args,
	});
};
