import type { SpeakerRead, SpeakerWrite } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, get, getPaginated, postCreate, put } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (sessionId?: string, presentationId?: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "presentations", id: presentationId },
	{ entity: "speakers", id },
];

interface GetPresentationSpeakersArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getPresentationSpeakers = async (args: GetPresentationSpeakersArgs): Promise<Paginated<SpeakerRead>> => {
	const { ...others } = args;
	return await getPaginated({
		module,
		entities: rootEntities(),
		...others,
	});
};

interface GetPresentationSpeakerArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
}

export const getPresentationSpeaker = async (args: GetPresentationSpeakerArgs): Promise<SpeakerRead> => {
	const { sessionId, presentationId, participantId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId, presentationId, participantId),
		...others,
	});
};

interface PostSpeakerArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
}

export const postSpeaker = async (args: PostSpeakerArgs): Promise<string> => {
	const { body, sessionId, presentationId, participantId, ...others } = args;
	return await postCreate<SpeakerWrite>(
		{
			module,
			entities: rootEntities(sessionId, presentationId, participantId),
			...others,
		},
		body,
	);
};

interface PutSpeakerArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	presentationId: string;
	participantId: string;
	body: SpeakerWrite;
}

export const putSpeaker = async (args: PutSpeakerArgs): Promise<boolean> => {
	const { body, sessionId, presentationId, participantId, ...others } = args;
	return await put<SpeakerWrite>(
		{
			module,
			entities: rootEntities(sessionId, presentationId, participantId),
			...others,
		},
		body,
	);
};
