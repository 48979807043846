import { useChairCreate, useChairUpdate, useSessionChair } from "@api";
import {
	ChairForm,
	type ChairModalForm,
	chairDefaultValues,
	chairReadToChairForm,
	chairReadToUserInformationProps,
	chairSchema,
	typeContactToParticipantWrite,
} from "@components";
import { chairFormToChairWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateChairFromContact } from "@hooks";
import {
	type CustomFieldForm,
	CustomFieldFormTabs,
	DialogAdvanced,
	type UserInformationProps,
	getDialogAdvanced,
	useContextModule,
	useSnackbar,
} from "@key4-front-library/core";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface ChairModalProps {
	sessionId: string;
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	chairId?: string;
}

export const ChairModal = (props: ChairModalProps) => {
	const component = "chairModal";
	const { sessionId, isOpened, onClose, chairId, onCallbackSubmit } = props;

	const [isLoaded, setIsLoaded] = useState(false);
	const [userInformation, setUserInformation] = useState<UserInformationProps>();

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const presentationChairQuery = useSessionChair(client.id, event.id, sessionId, chairId ?? "", { enabled: !!chairId });

	const form = useForm<CustomFieldForm<ChairModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: chairDefaultValues,
		resolver: zodResolver(chairSchema(chairId)),
	});

	useEffect(() => {
		if (presentationChairQuery.data) {
			setUserInformation(chairReadToUserInformationProps(presentationChairQuery.data));
		}
	}, [presentationChairQuery.data]);

	useEffect(() => {
		if ((presentationChairQuery.data || !chairId) && isLoaded) {
			form.reset(chairReadToChairForm(form.getValues("customFields"), presentationChairQuery.data));
		}
	}, [form.reset, presentationChairQuery.data, form.getValues, isLoaded, chairId]);

	const handleChairSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: chairId ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const createChairFromContact = useCreateChairFromContact({ onSuccess: handleChairSubmit });
	const createChair = useChairCreate({ onSuccess: handleChairSubmit });
	const chairEdit = useChairUpdate({ onSuccess: handleChairSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<ChairModalForm>) => {
			if (chairId && presentationChairQuery.data) {
				chairEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					participantId: presentationChairQuery.data.participantId,
					body: chairFormToChairWrite(data),
				});
				return;
			}
			if (data.participantOrContact?.category === "contacts") {
				createChairFromContact.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					body: chairFormToChairWrite(data),
					bodyParticipant: typeContactToParticipantWrite(data.participantOrContact),
				});
				return;
			}

			if (data.participantOrContact?.category === "participants") {
				createChair.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					participantId: data.participantOrContact.id,
					body: chairFormToChairWrite(data),
				});
				return;
			}
			snackbar.enqueue({ type: "unexpected" });
		},
		[client.id, event.id, chairId, chairEdit, sessionId, createChair, createChairFromContact, snackbar.enqueue, presentationChairQuery.data],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<ChairModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	if (userInformation === undefined && chairId) {
		return undefined;
	}

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: chairId ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
			fullWidth
		>
			<FormProvider {...form}>
				{
					<CustomFieldFormTabs
						onIsLoaded={handleIsLoaded}
						module="programme"
						scope="chair"
						businessForm={<ChairForm isEditMode={!!chairId} userInformation={userInformation} />}
					/>
				}
			</FormProvider>
		</DialogAdvanced>
	);
};
