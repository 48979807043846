import { t } from "i18next";
import update from "immutability-helper";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";

import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import RoomController from "@application/Controllers/RoomController";
import DraggableCard from "@application/components/_common/DraggableCard/DraggableCard";
import DraggableList from "@application/components/_common/DraggableCard/DraggableList";
import RoomCard from "@application/components/rooms/RoomCard";
import RoomEditionForm from "@application/components/rooms/RoomEditionForm";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import GetTranslationsForRooms from "@application/helpers/translations/GetTranslationsForRooms";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import { ConfirmationModal, ErrorAPI, TitleOld, useContextModule, useUserHasPermissions } from "@key4-front-library/core";
import { Stack } from "@mui/material";

import type { IRoom, IRoomForm } from "@domain/model/room.model";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
export const Rooms = () => {
	const canDeleteRoom = useUserHasPermissions(["programme_Room_Delete"]);

	const { client, event } = useContextModule();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [error, setError] = useState<ErrorAPI | undefined>();
	const [rooms, setRooms] = useState<Array<IRoom>>();
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});
	const [openRoomEditModal, setOpenRoomEditModal] = useState(false);
	const [room, setCurrentRoom] = useState<IRoomForm | undefined>();

	const [search, setSearch] = useState<string>("");

	const translations = GetTranslationsForRooms(room);

	const actionsConfigurations = GetMenuActionsConfigurations();
	const getConfig = (type: MenuActionsReference) => actionsConfigurations.find((x) => x.reference === type);

	const detailsConfig = getConfig(MenuActionsReference.DETAILS);
	const addConfig = getConfig(MenuActionsReference.ADD);
	const editConfig = getConfig(MenuActionsReference.EDIT);
	const deleteConfig = getConfig(MenuActionsReference.DELETE);
	const moveUpConfig = getConfig(MenuActionsReference.UP);
	const moveDownConfig = getConfig(MenuActionsReference.DOWN);

	const initComponent = useCallback(() => {
		RoomController.getListRoomsPagined(client.id, event.id, search, ["order"]).then((data: Array<IRoom> | ErrorAPI) => {
			if (data instanceof ErrorAPI) setError(data);
			setRooms(data as Array<IRoom>);
			setIsApiLoading(false);
		});
	}, [event, search]);

	useEffect(() => {
		initComponent();
	}, [event, initComponent]);

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setRooms((prevState: Array<IRoom> | undefined) =>
				update(prevState, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevState![dragIndex]],
					],
				}),
			);
		},
		[setRooms],
	);

	const handleEditModaleOpen = useCallback(
		(_roomId?: string) => {
			const _room = rooms?.find((room) => room.id === _roomId);
			setOpenRoomEditModal(true);
			setCurrentRoom(undefined);
			if (_room) {
				const roomForm = {
					id: _room.id,
					capacity: _room.capacity,
					name: _room.name,
				};
				setCurrentRoom(roomForm);
			}
		},
		[rooms],
	);

	const renderCard = useCallback(
		(card: IRoom, index: number) => {
			const handleSaveRoomsOrderOnDrop = (_rooms: Array<IRoom> | undefined) => {
				if (_rooms) RoomController.saveOrderRooms(client.id, event.id, _rooms);
			};

			const handleDeleteRoomClick = async (_id: string) => {
				if (_id) {
					try {
						await RoomController.deleteRoom(client.id, event.id, _id);
						enqueueSnackbar(translations.editionModal.returnMessages.success_deletion, { variant: "success" });
						setIsApiLoading(true);
						initComponent();
					} catch (error: any) {
						enqueueSnackbar(error.message, { variant: "error" });
					} finally {
						handleModaleClose();
					}
				}
			};
			const handleRoomMoveUp = () => {
				moveCard(index, index - 1);
			};

			const handleRoomMoveDown = () => {
				moveCard(index, index + 1);
			};

			const handleNavigateRoomClick = (roomId: string) => {
				navigate(`./${roomId}`);
			};

			const getMenu = (): IUiMenu => {
				const menu: IUiMenu = {
					items: [
						{
							label: t("old.programme.rooms.buttons.details"),
							reference: detailsConfig?.reference!,
							icon: detailsConfig?.icon!,
							onClick: (room: IRoomForm) => {
								handleNavigateRoomClick(room.id);
							},
						},
						{
							label: t("old.programme.rooms.buttons.edit"),
							reference: editConfig?.reference!,
							icon: editConfig?.icon!,
							onClick: (room: IRoomForm) => {
								handleEditModaleOpen(room.id);
							},
						},
					],
				};

				if (index > 0) {
					menu.items.push({
						label: t("old.programme.rooms.buttons.moveUp"),
						reference: moveUpConfig?.reference!,
						icon: moveUpConfig?.icon!,
						onClick: () => {
							handleRoomMoveUp();
						},
					});
				}
				if (rooms && index < rooms.length - 1) {
					menu.items.push({
						label: t("old.programme.rooms.buttons.moveDown"),
						reference: moveDownConfig?.reference!,
						icon: moveDownConfig?.icon!,
						onClick: () => {
							handleRoomMoveDown();
						},
					});
				}

				menu.items.push("divider");
				menu.items.push({
					label: t("old.programme.rooms.buttons.delete"),
					reference: deleteConfig?.reference!,
					icon: deleteConfig?.icon!,
					onClick: (_event: IRoom) => {
						confirmationModalProps.current = {
							action: EConfirmationModalAction.DELETE,
							handleAction: () => {
								handleDeleteRoomClick(_event.id);
							},
						};
						setOpenConfirmationModal(true);
					},
					isDisabled: !canDeleteRoom,
				});

				return menu;
			};

			return (
				<DraggableCard
					key={card.id}
					id={card.id}
					index={index}
					card={card}
					moveCard={moveCard}
					handleDropAction={() => {
						handleSaveRoomsOrderOnDrop(rooms);
					}}
				>
					<RoomCard
						room={card}
						menu={getMenu()}
						handleCardSingleClick={() => {
							handleNavigateRoomClick(card.id);
						}}
						handleCardDoubleClick={() => {
							handleEditModaleOpen(card.id);
						}}
					/>
				</DraggableCard>
			);
		},
		[
			moveCard,
			event,
			enqueueSnackbar,
			translations.editionModal.returnMessages.success_deletion,
			translations.buttons.details,
			translations.buttons.edit,
			translations.buttons.delete,
			translations.buttons.up,
			translations.buttons.down,
			initComponent,
			navigate,
			detailsConfig?.reference,
			detailsConfig?.icon,
			editConfig?.reference,
			editConfig?.icon,
			rooms,
			deleteConfig?.reference,
			deleteConfig?.icon,
			handleEditModaleOpen,
			moveUpConfig?.reference,
			moveUpConfig?.icon,
			moveDownConfig?.reference,
			moveDownConfig?.icon,
		],
	);

	const handleModaleClose = () => {
		setOpenConfirmationModal(false);
		setOpenRoomEditModal(false);
	};

	const handleSaveRoom = async (_room: IRoomForm, isCreateAnotherRoom: boolean) => {
		if (room) {
			try {
				await RoomController.updateRoom(client.id, event.id, _room);
				enqueueSnackbar(translations.editionModal.returnMessages.success_modification, { variant: "success" });
				setIsApiLoading(true);
				initComponent();
			} catch (error: any) {
				enqueueSnackbar(error.message, { variant: "error" });
			} finally {
				handleModaleClose();
			}
		} else {
			try {
				await RoomController.createRoom(client.id, event.id, _room);
				enqueueSnackbar(translations.editionModal.returnMessages.success_creation, { variant: "success" });
				setIsApiLoading(true);
				initComponent();
			} catch (_error: any) {
				(Object.keys(_error.message) as Array<keyof typeof _error.message>).forEach((key) => {
					enqueueSnackbar(_error.message[key], {
						variant: "error",
					});
				});
			} finally {
				!isCreateAnotherRoom && handleModaleClose();
			}
		}
	};

	const renderingRoleEditionModal = (translations: any) => {
		return (
			<DefaultModal open={openRoomEditModal} title={translations.editionModal.title} maxWidth={"sm"}>
				<RoomEditionForm room={room} handleSaveRoom={handleSaveRoom} handleModaleClose={handleModaleClose} translations={translations} />
			</DefaultModal>
		);
	};

	if (error) throw new Error();

	return (
		<>
			<TitleOld
				title={translations.title}
				buttons={[
					{
						label: translations.buttons.add,
						icon: { iconName: addConfig?.icon! },
						handleClick: () => {
							handleEditModaleOpen();
						},
					},
				]}
			/>
			<AppBox>
				<Stack spacing={2}>
					<SimpleTextSearchFilter
						handleChange={(value: string) => {
							setSearch(value);
						}}
					/>
					<DndProvider backend={HTML5Backend}>
						<DraggableList arrayOfCards={rooms} isLoading={isApiLoading} renderCard={renderCard} />
					</DndProvider>
				</Stack>
			</AppBox>

			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
			{renderingRoleEditionModal(translations)}
		</>
	);
};
