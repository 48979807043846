import { getClashIcon } from "@configurations";
import {
	Button,
	Chip,
	DatePaper,
	type DatePaperProps,
	Icon,
	Link,
	Stack,
	Tag,
	type TagProps,
	TextTooltip,
	TitleDivider,
	getTitleDivider,
} from "@key4-front-library/core";
import { Typography } from "@mui/material";
import { t } from "i18next";
import { uniqueId } from "lodash";
import { TimeSlotSection, type TimeSlotSectionProps } from "../TimeSlotSection";

export interface SchedulerCardPopoverProps {
	status?: TagProps;
	datePaper?: DatePaperProps;
	description?: string;
	hours?: string;
	hasClashes: boolean;
	shouldDisplayDatePaper: boolean;
	code?: string;
	tags?: Array<TagProps>;
	timeSlotSections: Array<TimeSlotSectionProps>;
	title?: string;
	to: string;
}

export const SchedulerCardPopover = (props: SchedulerCardPopoverProps) => {
	const { status, datePaper, description, shouldDisplayDatePaper, hours, code, timeSlotSections, tags, title, to, hasClashes } = props;
	const component = "schedulerCardPopoverContent";

	return (
		<Stack sx={{ width: "513px;" }} p={2} spacing={3}>
			<Stack spacing={2}>
				<Stack direction={"row"} spacing={1}>
					{shouldDisplayDatePaper && <DatePaper {...datePaper} />}
					<Stack width={"100%"}>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
							{hours ? (
								<Typography variant="subtitle2" color="textSecondary" textAlign={"center"}>
									{hours}
								</Typography>
							) : (
								<Typography variant="subtitle2" color="text.disabled">
									{t("noTime")}
								</Typography>
							)}
							<Stack direction={"row"} alignItems={"center"} spacing={1}>
								<Tag {...status} />
								{hasClashes && <Icon {...getClashIcon()} />}
							</Stack>
						</Stack>
						<Link to={to} color="inherit">
							{code ? (
								<TextTooltip
									line={1}
									maxHeight="32px"
									tooltip={{ placement: "bottom-start" }}
									typography={{
										children: code,
										variant: "h6",
										color: "primary",
									}}
								/>
							) : (
								<Typography variant="subtitle1medium" color="text.disabled">
									{t("noCode")}
								</Typography>
							)}
						</Link>
						<Link to={to} color="inherit">
							{title ? (
								<TextTooltip
									line={2}
									maxHeight="48px"
									tooltip={{ placement: "bottom-start" }}
									typography={{
										children: title,
									}}
								/>
							) : (
								<Typography variant="body1italic" color="text.disabled">
									{t(`${component}.noTitle`)}
								</Typography>
							)}
						</Link>
					</Stack>
				</Stack>
				{tags && tags.length > 0 && (
					<Stack direction={"row"} spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
						{tags.map((tag, index) => (
							<Tag key={uniqueId(index.toString())} {...tag} />
						))}
						{tags.length === 5 && (
							<Link to={to}>
								<Chip color="primary" label={<Typography>...</Typography>} />
							</Link>
						)}
					</Stack>
				)}
			</Stack>
			<Stack spacing={2}>
				<Stack spacing={1}>
					<TitleDivider
						{...getTitleDivider({
							type: "default",
							translation: `${component}.title.description`,
						})}
					/>
					<Stack>
						{description ? (
							<TextTooltip
								line={3}
								maxHeight="72px"
								tooltip={{ placement: "bottom-start" }}
								typography={{
									children: description,
								}}
							/>
						) : (
							<Typography variant="body1italic" color="text.disabled">
								{t(`${component}.noDescription`)}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack spacing={1}>
					<TitleDivider
						{...getTitleDivider({
							type: "default",
							translation: `${component}.title.presentations`,
						})}
					/>
					<Stack>
						{timeSlotSections.length > 0 ? (
							<Stack spacing={2}>
								{timeSlotSections.map((timeSlotSection, index) => (
									<Stack key={uniqueId(index.toString())}>
										<TimeSlotSection {...timeSlotSection} />
									</Stack>
								))}
							</Stack>
						) : (
							<Typography variant="body1italic" color="text.disabled">
								{t(`${component}.noPresentations`)}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
					<Button to={to} variant="text" endIcon={{ name: "arrow-up-right-from-square" }}>
						{t(`${component}.seeAll`)}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
