import type { FacultyInvitationStatus } from "@api";
import type { Theme } from "@mui/material";
import { t } from "i18next";

export const getFacultyStatus = (theme: Theme): Record<FacultyInvitationStatus, { name: string; color: string }> => ({
	unsent: { color: theme.palette.grey[700], name: t("speakerStatus.unsent") },
	declined: { color: theme.palette.error.main, name: t("speakerStatus.declined") },
	replaced: { color: theme.palette.error.main, name: t("speakerStatus.replaced") },
	cancelled: { color: theme.palette.neutral.main, name: t("speakerStatus.cancelled") },
	waitingForAnswer: { color: theme.palette.grey[500], name: t("speakerStatus.waitingForAnswer") },
	accepted: { color: theme.palette.success.main, name: t("speakerStatus.accepted") },
});
