import type { RoomRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "rooms", id }];

interface GetRoomArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getRooms = async (args: GetRoomArgs): Promise<Paginated<RoomRead>> => {
	return await getPaginated({
		module,
		entities: rootEntities(),
		...args,
	});
};
