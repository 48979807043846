import "@components/Atoms/Icon/Icon.configuration";
import { Licence } from "@key4-front-library/core";
import Router from "@key4-front-library/core/Bo/Layout/Router";
import { DEFAULT_TIMEZONE } from "@key4-front-library/core/Constants/Date";
import DevToolsHelpers from "@key4-front-library/core/DevToolsHelpers";
import { LicenseInfo } from "@mui/x-license-pro";
import { routes } from "@routes";
import { Settings } from "luxon";

import { version } from "../package.json";

LicenseInfo.setLicenseKey(Licence.mui);

const App = () => {
	// TODO: Remove when using the events' timezones
	Settings.defaultZone = DEFAULT_TIMEZONE;

	return (
		<>
			<DevToolsHelpers.VersionDevTools version={version} />
			<Router routes={routes} />
		</>
	);
};

export default App;
