import { BulletText, type BulletTextProps, Stack, TextTooltip, Typography } from "@key4-front-library/core";
import { Box, Divider } from "@mui/material";
import { t } from "i18next";
import { uniqueId } from "lodash";

export interface TimeSlotSectionProps {
	hours?: string;
	duration?: string;
	code?: string;
	body?: string;
	bulletTexts?: Array<BulletTextProps>;
}

export const TimeSlotSection = (props: TimeSlotSectionProps) => {
	const { body, duration, hours, bulletTexts, code } = props;

	return (
		<Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
			<Stack>
				{hours && duration ? (
					<Stack width={"92px"}>
						<Typography variant="subtitle2" color="textSecondary" textAlign={"center"}>
							{hours}
						</Typography>
						<Typography variant="subtitle2" color="textSecondary" textAlign={"center"}>
							{duration}
						</Typography>
					</Stack>
				) : (
					<Stack width={"92px"}>
						<Typography variant="subtitle2" color="text.disabled">
							{t("noTime")}
						</Typography>
					</Stack>
				)}
			</Stack>
			<Stack>
				<TextTooltip
					line={2}
					maxHeight="52px"
					tooltip={{ placement: "bottom-start", title: <Typography>{`${code} - ${t("noCode")}`}</Typography> }}
					typography={{
						children: (
							<Box>
								{code ? (
									<Typography key={uniqueId()} component={"span"} variant="subtitle1" color="primary">
										{`${code} - `}
									</Typography>
								) : (
									<Typography key={uniqueId()} component={"span"} variant="subtitle1" color="text.disabled">
										{`${t("noCode")} - `}
									</Typography>
								)}
								{body}
							</Box>
						),
					}}
				/>
				<Stack>
					{bulletTexts?.map((bulletText) => (
						<BulletText key={uniqueId()} {...bulletText} />
					))}
				</Stack>
			</Stack>
		</Stack>
	);
};
