import type { TagRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";
const rootEntities = (tagTypeId: string, id?: string): Array<PathEntity> => [
	{ entity: "tag-types", id: tagTypeId },
	{ entity: "tags", id },
];

interface GetTagsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	tagTypeId: string;
}

export const getTags = async (args: GetTagsArgs): Promise<Paginated<TagRead>> => {
	const { tagTypeId, ...others } = args;
	return await getPaginated({
		module,
		entities: rootEntities(tagTypeId),
		...others,
	});
};
