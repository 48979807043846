import type { PresentationBulkDateUpdate, SessionRead, SessionWrite } from "@api";
import type { PresentationEditSchedule, SessionEditSchedule } from "@application/Dialogs";
import type { DateTime } from "luxon";

export const sessionEditScheduleToSessionWrite = (src: SessionEditSchedule, dest: SessionRead, tagIds: Array<string>): SessionWrite => ({
	...dest,
	roomId: dest.room?.id,
	tagIds,
	startDate: src.newDateTimeStart?.toISO() ?? undefined,
	endDate: src.newDateTimeEnd && src.newDateTimeStart ? (setDateFromDateTime(src.newDateTimeEnd, src.newDateTimeStart).toISO() ?? undefined) : undefined,
});

const setDateFromDateTime = (target: DateTime, source: DateTime): DateTime => {
	return target.set({ year: source.year, month: source.month, day: source.day });
};

export const presentationEditScheduleToPresentationBulkDateUpdate = (
	src: Array<PresentationEditSchedule>,
	newStartDate: DateTime,
): Array<PresentationBulkDateUpdate> =>
	src.map((presentation) => ({
		id: presentation.id,
		startDate: !presentation.isWithoutTimeSlot ? setDateToDateTimeIfDefault(presentation.newDateTimeStart, newStartDate) : undefined,
		endDate: !presentation.isWithoutTimeSlot ? setDateToDateTimeIfDefault(presentation.newDateTimeEnd, newStartDate) : undefined,
	}));

/**
 * Set date to DateTime if it still the default value.
 * This occurs when the date is not set before the session re-schedule
 * avoids having datetime values like 0001-01-01T08:15:00Z
 */
export const setDateToDateTimeIfDefault = (dateTime: DateTime, defaultDateTime: DateTime): string => {
	if (!dateTime) {
		return "";
	}
	return dateTime.year === 1 ? `${defaultDateTime.toISODate()}T${dateTime.toISOTime()}` : (dateTime.toISO() ?? "");
};
