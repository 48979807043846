import { useEffect } from "react";

import { getProgrammeMailSenders } from "@api";
import ConfigurationApplication from "@application/Configurations/configuration";
import CustomFieldController from "@application/Controllers/CustomFieldController";
import FacultyController from "@application/Controllers/FacultyController";
import MailTemplatesController from "@application/Controllers/MailTemplateController";
import {
	BulkAction,
	type DtoMailTemplateGet,
	EBulkActionAction,
	type EBulkActionEntity,
	EnumMailTemplateType,
	EnumTarget,
	type ErrorAPI,
	FilteringOperator,
	Services,
	type TPickForm,
	type TUseBulkActionProps,
	filtersOrInToQueryString,
	getMailSenders,
	getOperationMailSenders,
	queryStringSorts,
	useBulkAction,
} from "@key4-front-library/core";
import type { TSelectItemWithIcon } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectIcon";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import type { GridValidRowModel } from "@mui/x-data-grid-pro";

const BulkActionFaculty = () => {
	const cbDataGridRows = async (clientId: string, eventId: string): Promise<number | ErrorAPI | Array<GridValidRowModel>> => {
		return await FacultyController.getFacultiesListBulkActionStepSelect(
			clientId,
			eventId,
			filters ? filters.search : "",
			[],
			filters ? filters.queryBuilderString : "",
		);
	};

	const cbStepActionSetupEditGetForm = async (clientId: string, eventId: string, entityType: EBulkActionEntity): Promise<ErrorAPI | Array<TPickForm>> => {
		return await CustomFieldController.getForms(clientId, eventId, entityType);
	};

	const hookBulkAction: TUseBulkActionProps = {
		cbDataGridRows,
		cbStepActionSetupEditGetForm,
	};

	const {
		handleActiveStepChange,
		activeEntity,
		client,
		event,
		filters,
		action,
		activeStep,
		setStepConfirmationIsAPIProcessing,
		getStepSelectData,
		setupStepChooseAction,
		handleConfirmationClick,
		setupClearStepConfirmation,
		setMailTemplatesList,
		isNoStepSelect,
		stepSelect,
		selectedItems,
		selectedMailTemplateId,
	} = useBulkAction(hookBulkAction);

	// #region 'STEP 1'
	useEffect(() => {
		getStepSelectData(client.id, event.id, ConfigurationApplication.Faculty.datagridHeaders, "");
	}, [event]);
	// #endregion 'STEP 1'

	// #region 'STEP 2'
	useEffect(() => {
		setupStepChooseAction(
			ConfigurationApplication.Faculty.bulkActionActions.map((action) => ({
				action,
				isAuthorized: true,
			})),
		);
	}, [activeEntity]);
	// #endregion 'STEP 2'

	const cbConfirm = async (clientId: string, eventId: string) => {
		let response: any = null;
		switch (action) {
			case EBulkActionAction.MAILING:
				for (const participantId of selectedItems) {
					const faculty = await Services.Events.Programme.FacultiesService.getDetails(clientId, eventId, participantId);

					ControllersBo.MailTemplatesController.sendMailToFaculty(clientId, eventId, selectedMailTemplateId, faculty.id);
				}
				break;
			default:
				response = await FacultyController.postFacultyBulkActionInvitations(clientId, eventId, filters, isNoStepSelect, stepSelect.rows ?? [], selectedItems);
				break;
		}

		return response;
	};

	// #region 'STEP 3'
	const fetchMailTemplatesList = async (): Promise<Array<DtoMailTemplateGet>> => {
		// We always want to display the "notification" mail templates first
		const sortQueryParam = queryStringSorts(["-type"]);

		const templates = (await MailTemplatesController.getMailTemplatesList(client.id, event.id, [...sortQueryParam])).filter(
			(template: DtoMailTemplateGet) => template.target === EnumTarget.Faculty,
		);

		if (templates.length === 0) {
			setMailTemplatesList([]);
			return [];
		}

		const mailSenders = await getMailSenders({
			clientId: client.id,
			queryStrings: [...filtersOrInToQueryString("id", FilteringOperator.Equal, [...new Set(templates.map((x) => x.mailSenderId) ?? [])])],
		});

		const operationMailSenders = await getOperationMailSenders({
			clientId: client.id,
			operationId: event.id,
			queryStrings: [...filtersOrInToQueryString("id", FilteringOperator.Equal, [...new Set(templates.map((x) => x.mailSenderId) ?? [])])],
		});

		const programmeMailSenders = await getProgrammeMailSenders({
			clientId: client.id,
			operationId: event.id,
			queryStrings: [...filtersOrInToQueryString("id", FilteringOperator.Equal, [...new Set(templates.map((x) => x.mailSenderId) ?? [])])],
		});

		const mailSendersAgg = [...mailSenders.data, ...operationMailSenders.data, ...programmeMailSenders.data];

		const templatesList: Array<TSelectItemWithIcon> = [];

		for (const template of templates) {
			const mailSender = mailSendersAgg.find((x) => x?.id === template.mailSenderId);
			templatesList.push({
				key: template.id,
				label: `${template.name} - ${mailSender?.email}`,
				icon: {
					name: template.type === EnumMailTemplateType.CUSTOM ? "palette" : "bell",
					prefix: "far",
				},
			});
		}
		setMailTemplatesList(templatesList);

		return templates;
	};

	useEffect(() => {
		if (activeStep === 3) {
			fetchMailTemplatesList();
		}
	}, [activeStep, event]);
	// #endregion 'STEP 3 EDIT'

	// #region 'STEP 4'
	const handleCallAPIClick = async () => {
		await handleConfirmationClick(cbConfirm);
	};

	// Clear stepConfirmation data from store when we leave step 4 page
	useEffect(() => {
		setupClearStepConfirmation();
	}, [activeStep]);
	// #endregion 'STEP 4'

	return (
		<BulkAction
			changeActiveStep={handleActiveStepChange}
			onCallAPIClick={async () => {
				setStepConfirmationIsAPIProcessing(true);
				await handleCallAPIClick();
			}}
		/>
	);
};

export default BulkActionFaculty;
