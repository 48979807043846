import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import SettingsController from "@application/Controllers/SettingsController";
import SettingsHelper from "@application/helpers/settings.helper";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import { DataGridOld, ErrorAPI, useContextModule } from "@key4-front-library/core";
import { Stack } from "@mui/material";
import type { GridCellParams, GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";

export const Numbering = () => {
	const { client, event } = useContextModule();

	const [rows, setRows] = useState<Array<GridValidRowModel>>([]);
	const [primaryTagLabel, setPrimaryTagLabel] = useState<string>("");

	const [columns, setColumns] = useState<Array<GridColDef>>();

	const fetchNumberingSettings = useCallback(async (clientId: string, eventId: string) => {
		const numberingSettingsData = await SettingsController.getNumberingSettings(clientId, eventId);

		if (numberingSettingsData instanceof ErrorAPI) return;
		setPrimaryTagLabel(numberingSettingsData.primaryTagLabel ?? "");
		setRows(numberingSettingsData.rows);
	}, []);

	useEffect(() => {
		fetchNumberingSettings(client.id, event.id);
		const headerLabels: Array<string> = t("old.common.settingsNumbering.datagrid.headerLabels", {
			returnObjects: true,
		}) as string[];
		setColumns(SettingsHelper.getNumberingHeaderDatagrid(headerLabels));
	}, []);

	const handleEditStop = async (params: GridCellParams, value: any) => {
		const body = {
			sessionPrefix: params.row.sessionPrefix,
			presentationPrefix: params.row.presentationPrefix,
		};

		// Interrupt action if value is the same
		if (body[params.field as "sessionPrefix" | "presentationPrefix"] === value) return;

		body[params.field as "sessionPrefix" | "presentationPrefix"] = value;

		const response = await SettingsController.editNumberingTag(client.id, event.id, params.row.id, body);

		if (response instanceof ErrorAPI) {
			enqueueSnackbar(t("old.common.errors.generic") ?? "b", {
				variant: "error",
			});
		} else {
			enqueueSnackbar(t(`old.common.settingsNumbering.edit.success.${params.field}`) ?? "a");
		}
	};

	return (
		<>
			{columns && (
				<IconCard
					title={t("old.common.settingsNumbering.title") + primaryTagLabel}
					icon={"list-ol"}
					iconPrefix={"fal"}
					children={
						<Stack mt={2}>
							<DataGridOld isAutoHeight={true} columns={columns} rows={rows} onCellEditStop={handleEditStop} isHideFooter={true} />
						</Stack>
					}
				/>
			)}
		</>
	);
};
