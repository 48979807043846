import type { ChairRead, ChairWrite } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, get, getPaginated, postCreate, put } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (sessionId?: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "chairs", id },
];

interface GetSessionChairsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSessionChairs = async (args: GetSessionChairsArgs): Promise<Paginated<ChairRead>> => {
	const { ...others } = args;
	return await getPaginated({
		module,
		entities: rootEntities(),
		...others,
	});
};

interface GetSessionChairArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
}

export const getSessionChair = async (args: GetSessionChairArgs): Promise<ChairRead> => {
	const { sessionId, participantId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId, participantId),
		...others,
	});
};

interface PostChairArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
	body: ChairWrite;
}

export const postChair = async (args: PostChairArgs): Promise<string> => {
	const { body, sessionId, participantId, ...others } = args;
	return await postCreate<ChairWrite>(
		{
			module,
			entities: rootEntities(sessionId, participantId),
			...others,
		},
		body,
	);
};

interface PutChairArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	participantId: string;
	body: ChairWrite;
}

export const putChair = async (args: PutChairArgs): Promise<boolean> => {
	const { body, sessionId, participantId, ...others } = args;
	return await put<ChairWrite>(
		{
			module,
			entities: rootEntities(sessionId, participantId),
			...others,
		},
		body,
	);
};
