import type { TagRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "chairs", id }];
interface GetChairQualitiesArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getChairQualities = async (args: GetChairQualitiesArgs): Promise<Paginated<TagRead>> => {
	const { ...others } = args;
	return await getPaginated({
		module,
		entities: [...rootEntities(), { entity: "qualities" }],
		...others,
	});
};
