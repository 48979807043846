import {
	faArrowDownLeftAndArrowUpRightToCenter,
	faArrowRight,
	faArrowTurnDownLeft,
	faArrowTurnDownRight,
	faArrowsRepeat,
	faBadgeCheck,
	faBars,
	faBed,
	faBooks,
	faBrowser,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarLinesPen,
	faChalkboardUser,
	faCircle,
	faCircleArrowLeft,
	faCircleArrowRight,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faCircleXmark,
	faClipboardList,
	faCrown,
	faEnvelope,
	faEnvelopeCircleCheck,
	faEnvelopeDot,
	faEnvelopeOpen,
	faFileChartColumn,
	faFileLines,
	faHandshake,
	faHeartCircleBolt,
	faHouse,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faListTree,
	faMessages,
	faMicrophone,
	faMobileButton,
	faPersonCircleCheck,
	faPodium,
	faRotateLeft,
	faSave,
	faScreenUsers,
	faSignalStream,
	faStore,
	faTriangleExclamation,
	faUser,
	faUserCrown,
	faUserGroup,
	faUserTie,
} from "@fortawesome/pro-solid-svg-icons";

export const SolidIcon = {};

export const SolidIconOld = {
	faArrowDownLeftAndArrowUpRightToCenter,
	faArrowRight,
	faArrowsRepeat,
	faArrowTurnDownLeft,
	faArrowTurnDownRight,
	faBadgeCheck,
	faBars,
	faBed,
	faBooks,
	faBrowser,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarLinesPen,
	faChalkboardUser,
	faCircle,
	faCircleArrowLeft,
	faCircleArrowRight,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faCircleXmark,
	faClipboardList,
	faCrown,
	faEnvelope,
	faEnvelopeCircleCheck,
	faEnvelopeDot,
	faEnvelopeOpen,
	faFileChartColumn,
	faFileLines,
	faHandshake,
	faHeartCircleBolt,
	faHouse,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faListTree,
	faMessages,
	faMicrophone,
	faMobileButton,
	faPersonCircleCheck,
	faPodium,
	faRotateLeft,
	faSave,
	faScreenUsers,
	faSignalStream,
	faStore,
	faTriangleExclamation,
	faUser,
	faUserCrown,
	faUserGroup,
	faUserTie,
};
