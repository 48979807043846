import { useRooms, useSessionTemplates, useSessionsTags, useTagTypes, useTags } from "@api";
import {
	type SecondariesTagsSelectReturn,
	type SessionModalForm,
	getSessionStatusesOptions,
	overrideWithSessionTemplate,
	roomsReadToAutocompleteItem,
	tagsReadToItems,
	tagsToSecondariesTagsSelect,
} from "@components";
import {
	type ActionItem,
	Autocomplete,
	Checkbox,
	DateTimePicker,
	DialogAdvanced,
	Divider,
	FilteringOperator,
	FormControl,
	type FormControlRenderArgs,
	Grid,
	type LabelWithCaptionProps,
	Select,
	type TagProps,
	TextField,
	filtersToQueryString,
	getDialogAdvanced,
	getRhfAutoComplete,
	getRhfCheckbox,
	getSelect,
	getSelectTag,
	getTextField,
	sortToQueryString,
	useContextModule,
	useToggle,
} from "@key4-front-library/core";
import { useTheme } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export interface SessionFormProps {
	id?: string;
}

export const SessionForm = (props: SessionFormProps): React.ReactNode => {
	const { id } = props;
	const component = "sessionForm";

	const { client, event } = useContextModule();
	const theme = useTheme();
	const form = useFormContext<SessionModalForm, undefined>();

	const [primaryTagOptions, setPrimaryTagOptions] = useState<Array<ActionItem<TagProps>>>([]);
	const [roomOptions, setRoomOptions] = useState<Array<ActionItem<LabelWithCaptionProps>>>([]);
	const [secondariesTagOptions, setSecondariesTagOptions] = useState<Array<SecondariesTagsSelectReturn>>([]);
	const [inputValue, setInputValue] = useState("");
	const { isToggle: isOpenSessionTemplate, toggle: toggleOpenSessionTemplate, setIsToggle: setOpenSessionTemplateIsToggle } = useToggle();

	const roomsQuery = useRooms(client.id, event.id);
	const tagTypesQuery = useTagTypes(client.id, event.id, { queryStrings: sortToQueryString([{ field: "order", sort: "asc" }]) });
	const primaryTagsQuery = useTags(client.id, event.id, tagTypesQuery.data?.data?.[0].id ?? "", { enabled: !!tagTypesQuery.data?.data?.[0].id });
	const sessionTagsQuery = useSessionsTags(client.id, event.id, {
		enabled: !!tagTypesQuery.data?.pagination?.totalCount && tagTypesQuery.data?.pagination?.totalCount > 1,
		queryStrings: filtersToQueryString(
			`tagTypeId${FilteringOperator.Equal}${tagTypesQuery.data?.data
				.slice(1)
				.map((tagType) => tagType.id)
				.join(FilteringOperator.OrIn)}`,
		),
	});
	const sessionTemplatesQuery = useSessionTemplates(client.id, event.id);

	const primaryTagLabel = tagTypesQuery?.data?.data[0].label;
	const watchPrimaryTag = useWatch({ control: form.control, name: "primaryTagId" });

	useEffect(() => {
		if (primaryTagsQuery?.data?.data) {
			setPrimaryTagOptions(tagsReadToItems(primaryTagsQuery?.data?.data));
		}
	}, [primaryTagsQuery?.data?.data]);

	useEffect(() => {
		if (roomsQuery?.data?.data) {
			setRoomOptions(roomsReadToAutocompleteItem(roomsQuery?.data?.data, component));
		}
	}, [roomsQuery?.data?.data]);

	useEffect(() => {
		if (sessionTagsQuery?.data?.data && tagTypesQuery?.data?.data) {
			setSecondariesTagOptions(tagsToSecondariesTagsSelect(tagTypesQuery?.data?.data, sessionTagsQuery?.data?.data, form));
		}
	}, [sessionTagsQuery?.data?.data, tagTypesQuery?.data?.data, form]);

	useEffect(() => {
		if (
			watchPrimaryTag &&
			sessionTemplatesQuery?.data?.data &&
			sessionTemplatesQuery.data.data.filter((sessionTemplates) => sessionTemplates.primaryTagId === watchPrimaryTag).length > 0 &&
			!id
		) {
			setOpenSessionTemplateIsToggle(true);
		}
	}, [watchPrimaryTag, sessionTemplatesQuery?.data?.data, setOpenSessionTemplateIsToggle, id]);

	const handleTagOptionDisabled = useCallback(
		(option: unknown, args: FormControlRenderArgs<unknown>, max?: number) =>
			max !== undefined &&
			(args.value as Array<ActionItem>).length >= (max ?? 0) &&
			!(args.value as Array<ActionItem>).filter((val) => val.value === (option as ActionItem).value).length,
		[],
	);

	const handleSessionTemplateConfirm = () => {
		const sessionTemplate = sessionTemplatesQuery?.data?.data.filter((sessionTemplates) => sessionTemplates.primaryTagId === watchPrimaryTag)?.[0];
		if (sessionTemplate) {
			overrideWithSessionTemplate(form, sessionTemplate);
		}
		toggleOpenSessionTemplate();
	};

	const handleSessionTemplateCancel = () => {
		form.setValue("shouldApplySessionTemplate", false);
		toggleOpenSessionTemplate();
	};

	return (
		<>
			<DialogAdvanced
				open={isOpenSessionTemplate}
				{...getDialogAdvanced({
					type: "confirm",
					translation: `${component}.sessionTemplate`,
					options: {
						onCancelClick: handleSessionTemplateCancel,
						onActionClick: handleSessionTemplateConfirm,
					},
				})}
			/>
			<Grid container spacing={2} pt={2}>
				<FormControl name={"code"} gridSize={{ xs: 4 }} render={(args) => <TextField {...args} label={t(`${component}.code`)} {...getTextField({})} />} />
				<FormControl
					name={"primaryTagId"}
					gridSize={{ xs: 4 }}
					render={(args) => <Select {...args} label={primaryTagLabel} {...getSelectTag(primaryTagOptions, args.value)} />}
				/>
				<FormControl
					name={"organizedBy"}
					gridSize={{ xs: 4 }}
					render={(args) => <TextField {...args} label={t(`${component}.organizedBy`)} {...getTextField({})} />}
				/>
				<FormControl name={"title"} render={(args) => <TextField {...args} label={t(`${component}.title`)} {...getTextField({})} />} />
				<FormControl
					name={"description"}
					render={(args) => <TextField {...args} label={t(`${component}.description`)} {...getTextField({ type: "textarea" })} minRows={5} />}
				/>
				<FormControl
					name={"isPrivate"}
					type={"checkbox"}
					render={(args) => (
						<Checkbox
							{...getRhfCheckbox(args, {
								type: "default",
								translation: t(`${component}.isPrivate`),
							})}
						/>
					)}
				/>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<FormControl
					name={"expectedAudience"}
					gridSize={{ xs: 4 }}
					render={(args) => (
						<TextField
							{...args}
							label={t(`${component}.expectedAudience`)}
							{...getTextField({ type: "number" })}
							inputProps={{
								min: 0,
							}}
						/>
					)}
				/>
				<FormControl
					name={"roomId"}
					gridSize={{ xs: 8 }}
					render={(args) => (
						<Autocomplete
							id="roomId"
							{...args}
							inputValue={inputValue}
							onInputChange={(_event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							label={t(`${component}.roomId`)}
							{...getRhfAutoComplete({ type: "labelWithCaption" }, roomOptions, args.onChange)}
						/>
					)}
				/>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<FormControl name={"startDate"} gridSize={{ xs: 4 }} render={(args) => <DateTimePicker {...args} label={t(`${component}.startDate`)} />} />
				<FormControl name={"endDate"} gridSize={{ xs: 4 }} render={(args) => <DateTimePicker {...args} label={t(`${component}.endDate`)} />} />
				<FormControl
					name={"duration"}
					gridSize={{ xs: 4 }}
					render={(args) => <TextField {...args} label={t(`${component}.duration`)} {...getTextField({})} />}
				/>
				<FormControl
					name={"status"}
					gridSize={{ xs: 4 }}
					render={(args) => <Select label={t(`${component}.status`)} {...args} {...getSelect({ type: "bulletText" }, getSessionStatusesOptions(theme))} />}
				/>
				<FormControl name={"publicationDate"} gridSize={{ xs: 4 }} render={(args) => <DateTimePicker {...args} label={t(`${component}.publicationDate`)} />} />
				<Grid item xs={12}>
					<Divider />
				</Grid>
				{secondariesTagOptions?.map((secondaryTag) => (
					<FormControl
						key={secondaryTag.key}
						name={`tags.${secondaryTag.key}`}
						gridSize={{ xs: 4 }}
						render={(args) => (
							<Autocomplete
								{...args}
								value={args.value as Array<ActionItem<TagProps>>}
								{...secondaryTag.autocomplete}
								{...getRhfAutoComplete({ type: "multiTag" }, secondaryTag.options, args.onChange)}
								getOptionDisabled={(option) => handleTagOptionDisabled(option, args, secondaryTag.max)}
							/>
						)}
					/>
				))}
			</Grid>
		</>
	);
};
